
import { UPDATES_MUTATIONS } from './constants';

export async function getUpdates({ commit },
    { param }) {
    try {
        let updates = [];
        commit(UPDATES_MUTATIONS.UPDATES_PROGRESS);
        await param.getUpdates(1).then(async (data) => {
            updates.push(data.updates);
        });
        commit(UPDATES_MUTATIONS.UPDATES_SUCCESS, updates);
    } catch(error) {
        commit(UPDATES_MUTATIONS.UPDATES_FAILURE, error);
    }
}