<template>
  <section>
    <div class="sliding-banner banner-wrapper">
      <div class="container-fluid col-10" v-if="!isMobile">
        <div >
          <div class="col first-banner card">
            <div class="d-flex align-items-center">
              <img :src="$S3_url+'/Assets/images/banner-img.svg'" alt="Post your video resume and search jobs for freshers and entry-level hiring">
              <div class="ml-15">
                <h4 class="banner-text-desktop">Showcase your<br>
                <span>Video Profile</span> #visume<br> to find matching jobs </h4>
                <p class="pl-0 col-10">India's largest video profile based job site with 3 million+ active job seekers and 5000+ companies hiring through us</p>
                <b-button href="/jobs" target="_blank" class="btn btn-primary text-white"><strong>Search jobs</strong> </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <VueSlickCarousel class="" v-if="!isMobile" :dots="true" centerPadding="20" :variableWidth="true" :centerMode="true" :infinite="true" :slidesToShow="1" :speed="3000" :autoplaySpeed="5000" :slidesPerRow="1" :rows="1" :autoplay="true" >
        <div class="banner-slide">
          <div class="row banner-container">
            <div class="col-md-4 banner-image">
              <picture>
                <img loading="lazy" width="290" height="295" :src="$S3_url+'/Assets/images/banner-img.svg'" alt="Post your video resume and search jobs for freshers and entry-level hiring" class="banner_img">
              </picture>
            </div>

            <div class="col-md-8">
              <div class="row mt-5">
                <div class="col banner-text">
                  <h4>Showcase your<br>
                  <span>Video Profile</span> #visume<br> and find the matching jobs </h4>
                  <p>India's largest video profile based job site with 3 million+ active job seekers and 5000+ companies hiring through us</p>
                  <a class="btn btn-primary text-white" href="/jobseeker/register" target="_blank"><strong>Register now </strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="banner-slide">
          <div class="row banner-container ">
            <div class="col-md-4 banner-image">
              <picture>
                <img loading="lazy" width="290" height="295" :src="$S3_url+'/Assets/images/upload-visume.svg'" alt="Post your video resume and search jobs for freshers and entry-level hiring" class="banner_img">
              </picture>
            </div>

            <div class="col-md-8">
              <div class="row mt-5">
                <div class="col banner-text">
                  <h4>Post your 2 min <br>
                  <span>Video Profile</span> #visume<br> Your face is the best interface </h4>
                  <p>Create a good first impression - Video profile brings life to your profile & increase 
                    your chances to shortlist by recruiters 
                    </p>
                  <a class="btn btn-primary text-white" href="/jobseeker/register" target="_blank"><strong>Post your visume</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="banner-slide" v-if="!$cookies.get('hire-seeker')">
          <div class="row banner-container">
            <div class="col-md-4 banner-image">
              <picture>
                <img loading="lazy" width="290" height="295" :src="$S3_url+'/Assets/images/hire-banner-img.svg'" alt="Post your video resume and search jobs for freshers and entry-level hiring" class="banner_img">
              </picture>
            </div>

            <div class="col-md-8">
              <div class="row mt-5">
                <div class="col banner-text">
                  <h4 class="mt-2">Looking to hire candidates? <br>Shortlist candidates from <br>
                  <span>Video Profiles</span> #visume </h4>
                  <p>Hire best matching talents from largest pool of candidate video profiles & 
                    <br>make your   pre-screening virtually 
                  </p>
                  <a class="btn btn-primary text-white" href="/recruit/post-job"><strong>Post free job</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel> -->
    <div class="container mt-6" v-if="isMobile">
            <div class="row">
              <div class="col p-2 card m-2">
                <div class="banner-text-head card-body">
                    <h4>Showcase your<br>
                    <span>Video Profile</span> #visume<br> to find matching jobs </h4>
                    <p>India's largest video profile based job site with 3 million+ active job seekers and 5000+ companies hiring through us</p>
                    <a class="btn btn-primary text-white" href="/jobs" target="_blank"><strong>Search jobs</strong></a>
                </div>
              </div>
            </div>
          </div>
      <!-- <div class="container m-search" v-if="isMobile">
        <div class="col-md-12 ">
          mobile serach bar
          <div class="row">
            <div class="col-12 p-0">
              <a href="/jobs" variant="outline-secondary" class="btn m-button btn-outline-secondary"><i class="mr-3 mdi mdi-magnify cust-icon"></i> Search jobs</a>
            </div>
          </div>
        mobile search bar end
        </div>
      </div> -->

      <!-- <VueSlickCarousel :dots="true" class="" v-if="isMobile"  :infinite="true" :slidesToShow="1" :speed="3500" :autoplaySpeed="8000" :slidesPerRow="1" :rows="1" :autoplay="true" >
        <div class="banner-slide">
          <div class="row banner-container">
            <div class="col-md-8 col-sm-12">
              <div class="row mt-5">
                <div class="banner-text">
                  <h4>Showcase your<br>
                  <span>Video Profile</span> #visume<br> and find the matching jobs </h4>
                  <p>India's largest video profile based job site with 3 million+ active job seekers & 5000+ companies hiring through us</p>
                  <a class="btn btn-primary text-white" href="/jobseeker/register"><strong>Register now</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="banner-slide">
          <div class="row banner-container ">
            <div class="col-md-8 col-sm-12">
              <div class="row mt-5">
                <div class="col banner-text">
                  <h4>Post your 2 min <br>
                  <span>Video Profile</span> #visume<br> Your face is the best interface </h4>
                  <p>Create a good first impression - Video profile brings life to your profile & increase your chances to shortlist by recruiters </p>
                  <a class="btn btn-primary text-white" href="/jobseeker/register"><strong>Post your visume</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel> -->

      <div class="m-hide">
        <!--Popular categories-->
        <div class="col" :class="isMobile?'mt-4':''">
          <div class="container popular-categories">
            <div class="row" style="justify-content: center !important;">
              <h1><a href="/jobs-for-freshers" class="subhead" target="_blank">Fresher jobs</a></h1>
              <a target="_blank" href="/govt-jobs-jobs-vacancies">Government jobs</a>
              <a target="_blank" href="/it-software-jobs-vacancies">IT-software</a>
              <a target="_blank" href="/bank-jobs-vacancies">Bank</a>
              <a target="_blank" href="/defence-jobs-vacancies">Sales & Marketing</a>
              <a target="_blank" href="/apprenticeship-jobs-vacancies">Apprenticeship</a>
            </div>
          </div>
        </div>
        <!--Popular categories end-->
      </div>
    </div>
<!-- 
    <div class="container search-container container-xl" v-if="!isMobile" style="border-bottom: 1px solid #eceaea;">
      <div class="row justify-content-md-center">
        <div class="col col-lg-3">
          <div class="row">
            <div class="col-12">
              <input class="form-control keyword_field" v-model="filter.search" name="keyword" type="text"  placeholder="Job title, keywords, company">
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="row">
            <div class="col-12">
              <v-autocomplete v-model="filter.course_id" :items="courses" item-text="text" item-value="id" label="Select course" outlined></v-autocomplete>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-12">
              <v-autocomplete v-model="filter.city_id" label="Select location" :items="locations" item-text="text" item-value="id" outlined> 
                <template slot="item" slot-scope="data">
                  <template v-if = typeof(data.item) != 'object'>
                    <v-list-item-content v-text="data.item.text"/>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"/>
                      <v-list-item-sub-title v-html="data.item.group"/>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
          </div>
        </div>
        <div class="col col-lg-2">
          <h2 class="btn btn-primary find-btn" @click="getJobs">Find jobs</h2>
        </div>
      </div>
    </div>

    <div class="sidebar mobile-search" id="m-search">
      <div class="container">
        <button class="sidebar-close" @click="msearch_close">
          <i class="mdi mdi-close"></i>
        </button>
        <div class="row justify-content-md-center">
          <div class="col col-lg-3">
            <div class="row">
              <div class="col labelbig">Keyword</div>
            </div>
            <div class="row">
              <div class="col-12">
                <input class="form-control fields" v-model="filter.search" name="keyword" type="text" placeholder="Job title, keywords, company ..."/>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="row">
              <div class="col labelbig">Course</div>
            </div>
            <div class="row">
              <div class="col-12">
                <v-autocomplete v-model="filter.course_id" :items="courses" item-text="text" item-value="id" label="Select course" outlined></v-autocomplete>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="row">
              <div class="col labelbig">Location</div>
            </div>
            <div class="row">
              <div class="col-12">
                <v-autocomplete v-model="filter.city_id" label="Select location" :items="locations" item-text="text" item-value="id" outlined>
                  <template slot="item" slot-scope="data">
                    <template v-if= "typeof data.item" != "object">
                      <v-list-item-content v-text="data.item.text" />
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text" />
                        <v-list-item-sub-title v-html="data.item.group" />
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
            </div>
          </div>
          <div class="col col-lg-2">
            <span style="font-size:1rem !important;font-weight:400 !important;" class="btn btn-primary find-btn" v-on:click= "getJobs" >Find jobs</span>
          </div>
        </div>
      </div>
      <div v-on:click="msearch_close" class="closeOut"></div>
    </div> -->

    <!-- mobile serach bar window -->

    <div class="col mt-5 pb-0 latest-job" v-if="isMobile">
      <div class="row">
        <span style="font-size:22px !important;font-weight:bold !important;" class="content-center">Latest jobs</span> 
      </div>
      <div class="col mt-5 pb-0">
        <!--row gap-->
        <div class="container container-pd-0" v-if="jobs && !jobs_loading">
          <div class="card border-shadow mb-5 job-card" v-for="job in jobs" :key="job.job_id">
            <div class="card-body">
              <div class="row">
               <div class="col-md-9">
                  <h5 class="card-text"><a :href="'/jobs/'+job.param" style="text-decoration: none;color: #333;">{{job.job_designation}} </a>
                    <b-badge pill variant="warning" v-if="job.job_type == 1">Full time</b-badge>
                    <b-badge pill variant="primary" v-if="job.job_type == 2">Work from home</b-badge>
                    <b-badge pill variant="secondary" v-if="job.job_type == 3">Hybrid</b-badge>
                    <b-badge pill variant="success" v-if="job.job_type == 4">Part time</b-badge>
                    <b-badge pill variant="info" v-if="job.job_type == 5">Internship</b-badge>
                    <b-badge pill variant="danger" v-if="job.job_type == 6">Apprenticeship</b-badge>
                  </h5>

                  <div class="mt-0 row">
                    <div class="mr-2 details d-flex" style="color: #157efb; font-weight:500">
                      <div>
                        <span><v-icon class="mdi mr-1 mb-2" size="14px" color="#6c757d">{{ $icons.layers }}</v-icon>{{job.company_name}}</span>
                        <span><v-icon class="mdi mr-1 mb-2" size="14px" color="#6c757d">{{ $icons.mapMarker }}</v-icon>{{job.cities.join()}}</span>
                        <span v-if="!job.salary_flag">
                          <v-icon class="mdi mr-1 mb-2" size="14px" color="#6c757d">{{ $icons.cashMultiple }}</v-icon>
                          <span class="pl-1 pr-0">Rs.{{job.salary_range_from}}</span>
                          <span class="pl-1 pr-0" v-if="job.salary_range_to" >- Rs.{{job.salary_range_to}}</span> per month
                        </span>
                      </div>
                      <div></div>
                      <div></div>

                      <!-- <span><v-icon class="mdi mr-1 mb-1" size="14px" color="#6c757d">{{ $icons.layers }}</v-icon> {{job.company_name}}</span>
                      <span class=" text-muted" v-if="job.cities" style="word-break:break-all;">
                        <v-icon class="mdi mr-1 mb-1" size="14px" color="#6c757d">{{ $icons.mapMarker }}</v-icon>{{job.cities.join()}}
                      </span> 
                      <div class="jobIcon ml-2 d-flex" v-if="!job.salary_flag&&job.salary_range_from > 0">
                        <v-icon class="mdi mb-3" size="14px" color="#6c757d">{{ $icons.cashMultiple }}</v-icon>
                        <span>Rs.{{job.salary_range_from}}</span><span v-if="job.salary_range_to"> - Rs.{{job.salary_range_to}}</span> <span>per month</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-3 text-right">
                  <div data-toggle="modal" data-target="#quickapplyModal" class="btn btn-primary"><a :href="'/jobs/'+job.param" style="text-decoration: none;color: white;" target="_blank">View & Apply</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container container-pd-0" v-if="jobs_loading">
          <div class="card border-shadow mb-5 job-card" style="height:125px;" v-for="n in 3" :key="n">
            <div class="card-body">
              <div class="row">
                <div class="col-md-9">
                  <content-loader viewBox="0 0 1500 50" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="jobs_loading">
                    <rect x="0" y="5" rx="3" ry="3" width="100" height="10" />
                    <rect x="110" y="5" rx="3" ry="3" width="40" height="10" />
                    <rect x="400" y="5" rx="3" ry="3" width="40" height="20" />
                    <rect x="0" y="30" rx="3" ry="3" width="30" height="6" />
                    <rect x="40" y="30" rx="3" ry="3" width="30" height="6" />
                    <rect x="80" y="30" rx="3" ry="3" width="30" height="6" />
                  </content-loader>
                </div>
              </div>
            </div>
          </div>
        </div>                
      </div>
    </div>
    <div class="col lastest-page-m  mr-5 pt-0">
      <div class="container">
        <div class="row text-right">
          <div class="col-lg-12 col-md-4 col-sm-4">
            <a class="text-decoration-none btn btn-outline-primary" href="/jobs" target="_blank"> View all jobs</a>
          </div>
        </div>
      </div>
    </div>

    
    <div style="background-color:#dedede" v-if="isMobile">
      <div class="col">
        <div class="container popular-categories">
          <div class="row" style="justify-content: center !important;">
            <h1><a href="/jobs-for-freshers" class="subhead" target="_blank">Fresher jobs</a></h1>
            <a target="_blank" href="/govt-jobs-jobs-vacancies">Government jobs</a>
            <a target="_blank" href="/it-software-jobs-vacancies">IT-software</a>
            <a target="_blank" href="/bank-jobs-vacancies">Bank</a>
            <a target="_blank" href="/defence-jobs-vacancies">Sales & Marketing</a>
            <a target="_blank" href="/apprenticeship-jobs-vacancies">Apprenticeship</a>
          </div>
        </div>
      </div>
      <!--Popular categories end-->
    </div>


    <div class="col">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <h3>Jobs from popular cities</h3>
          </div>
        </div>
        <b-row class="p-city" cols="2" cols-sm="2" cols-md="5" cols-lg="5">
          <b-col>
            <a href="/jobs-in-bangalore" class="popular_jobs" target="_blank">
              <b-card :img-src="$S3_url+'/Assets/images/logo/bangalore.svg'" img-alt="Jobs in bangalore" :img-width="isMobile?'125':'125'" :img-height="isMobile?'90':'120'" img-top>
                <b-card-text>
                  Bangalore
                </b-card-text>
              </b-card>
            </a>
          </b-col>
          <b-col>
            <a href="/jobs-in-mumbai" class="popular_jobs" target="_blank">
              <b-card :img-src="$S3_url+'/Assets/images/logo/mumbai.svg'" img-alt="Jobs in mumbai" :img-width="isMobile?'125':'125'" :img-height="isMobile?'90':'120'" img-top>
                <b-card-text>
                  Mumbai
                </b-card-text>
              </b-card>
            </a>
          </b-col>
          <b-col>
            <a href="/jobs-in-chennai" class="popular_jobs" target="_blank">
              <b-card :img-src="$S3_url+'/Assets/images/logo/chennai.svg'" img-alt="Jobs in chennai" :img-width="isMobile?'125':'125'" :img-height="isMobile?'90':'120'" img-top>
                <b-card-text>
                  Chennai
                </b-card-text>
              </b-card>
            </a>
          </b-col>
          <b-col>
            <a href="/jobs-in-hyderabad" class="popular_jobs" target="_blank">
              <b-card :img-src="$S3_url+'/Assets/images/logo/hyderabad.svg'" img-alt="Jobs in hyderabad" :img-width="isMobile?'125':'125'" :img-height="isMobile?'90':'120'" img-top>
                <b-card-text>
                  Hyderabad
                </b-card-text>
              </b-card>
            </a>
          </b-col>
          <b-col>
            <a href="jobs-in-delhi" class="popular_jobs" target="_blank">
              <b-card :img-src="$S3_url+'/Assets/images/logo/delhi.svg'" img-alt="Jobs in delhi" :img-width="isMobile?'125':'125'" :img-height="isMobile?'90':'120'" img-top>
                <b-card-text>
                Delhi
                </b-card-text>
              </b-card>
            </a>
          </b-col>
          <b-col>
            <a href="jobs-in-kolkata" class="popular_jobs" target="_blank">
              <b-card :img-src="$S3_url+'/Assets/images/logo/kolkata.svg'" img-alt="Jobs in kolkata" :img-width="isMobile?'125':'125'" :img-height="isMobile?'90':'120'" img-top>
                <b-card-text>
                  Kolkata
                </b-card-text>
              </b-card>
            </a>
          </b-col>
          <b-col>
            <a href="jobs-in-ahmedabad" class="popular_jobs" target="_blank">
              <b-card :img-src="$S3_url+'/Assets/images/ahamedabad.svg'" img-alt="Jobs in ahamedabad" :img-width="isMobile?'125':'125'" :img-height="isMobile?'90':'120'" img-top>
                <b-card-text>
                  Ahmedabad
                </b-card-text>
              </b-card>
            </a>
          </b-col>
          <b-col>
            <a href="/jobs-by-cities" class="popular_jobs" target="_blank" >
              <b-card :img-src="$S3_url+'/Assets/images/more-cities.svg'" img-alt="Jobs in all cities" :img-width="isMobile?'125':'125'" :img-height="isMobile?'90':'120'" img-top>
                <b-card-text class="more-cities">
                  <span>800+</span> cities
                </b-card-text>
              </b-card>
            </a>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="col video_tab" style="background-color: #fafafa;">
      <div class="container" style="margin-top:50px;">
        <div class="row">
          <div class="col-md-8">
            <video width="640" height="360" :poster="$S3_url+'/Assets/images/maxresdefault.jpg'" controls="controls" preload="none">
            <source :src="$S3_url+'/Assets/videos/hireclap-hire-matching-candidates.mp4'" type="video/mp4" title="hireclap-video" alt="Post your video profile"/>
            <source src="movie.ogg" type="video/ogg" />Your browser does not support the video tag.
            </video>
          </div>
          <div class="col-md-4">
            <div >
              <div class="row video-tab-text">
                <div style="font-size: 31px;font-weight: bold; color: black;">Post your 2 min Video profile  <span class="blue-text">#visume</span></div>
                <p style="margin-top: 6px;">Impress recruiters with your introductory video profile #visume. Showcase your personality, points on why should they hire you? A video profile brings life to your profile - increases recruiter interest to review your profile.</p>
                <a href="/jobseeker/register" target="_blank" style="text-decoration: none;">Post your visume</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col mt-15 pb-0 latest-job" v-if="!isMobile">
      <div class="row">
        <span style="font-size:22px !important;font-weight:bold !important;" class="content-center">Latest jobs</span> 
      </div>
      <div class="col mt-10 pb-0">
        <!--row gap-->
        <div class="container container-pd-0" v-if="jobs && !jobs_loading">
          <div class="card border-shadow mb-5 job-card" v-for="job in jobs" :key="job.job_id">
            <div class="card-body">
              <div class="row">
               <div class="col-md-9">

                  <!-- <a style="text-decoration: none;color:#212529"> -->
                        <h5 class="card-text"><a :href="'/jobs/'+job.param" style="text-decoration: none;color: #333;">{{job.job_designation}} </a>
                          <b-badge pill variant="warning" v-if="job.job_type == 1">Full time</b-badge>
                          <b-badge pill variant="primary" v-if="job.job_type == 2">Work from home</b-badge>
                          <b-badge pill variant="secondary" v-if="job.job_type == 3">Hybrid</b-badge>
                          <b-badge pill variant="success" v-if="job.job_type == 4">Part time</b-badge>
                          <b-badge pill variant="info" v-if="job.job_type == 5">Internship</b-badge>
                          <b-badge pill variant="danger" v-if="job.job_type == 6">Apprenticeship</b-badge>
                        </h5>

                  <!-- </a> -->
                      <div class="mt-0 row">
                        <div class="mr-2 col-md-12 details" style="color: #157efb; font-weight:500">
                          <span><i class="mdi mdi-layers"></i> {{job.company_name}}</span>
                          <span class=" text-muted" v-if="job.cities" style="word-break:break-all;">
                            <i class="mdi mdi-map-marker"></i>{{job.cities.join()}}
                            <!-- <span style="color:#6c757d;text-decoration: none;" v-for="(city, index) in job.cities" :key="index">{{city}} ,</span> -->
                          </span> 
                          <!-- <span class=" text-muted">
                            <i class="mdi mdi-clock"></i> {{job.date_of_posting  | moment("from", "now", true) }} ago
                          </span> -->
                            <span class="jobIcon ml-2" v-if="!job.salary_flag&&job.salary_range_from && job.salary_range_from > 0"><img :src="$S3_url+'/Assets/images/money.svg'" alt="salary" title="salary" width="21"></span>
                            <span v-if="!job.salary_flag&&job.salary_range_from && job.salary_range_from">
                              <span v-if="job.salary_range_from" class="p-0">Rs.</span>{{job.salary_range_from}}
                              <span v-if="job.salary_range_from&&job.salary_range_to" class="p-0">-</span>
                              <span v-if="job.salary_range_to" class="p-0">Rs.</span>{{job.salary_range_to}} per month
                            </span>
                        </div>
                       
                      </div>


                </div>
                <div class="col-md-3 text-right">
                  <div data-toggle="modal" data-target="#quickapplyModal" class="btn btn-primary"><a :href="'/jobs/'+job.param" style="text-decoration: none;color: white;" target="_blank">View & Apply</a></div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container container-pd-0" v-if="jobs_loading">
          <div class="card border-shadow mb-5 job-card" style="height:125px;" v-for="n in 3" :key="n">
            <div class="card-body">
              <div class="row">
                <div class="col-md-9">
                  <content-loader viewBox="0 0 1500 50" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="jobs_loading">
                    <rect x="0" y="5" rx="3" ry="3" width="100" height="10" />
                    <rect x="110" y="5" rx="3" ry="3" width="40" height="10" />
                    <rect x="400" y="5" rx="3" ry="3" width="40" height="20" />
                    <rect x="0" y="30" rx="3" ry="3" width="30" height="6" />
                    <rect x="40" y="30" rx="3" ry="3" width="30" height="6" />
                    <rect x="80" y="30" rx="3" ry="3" width="30" height="6" />
                  </content-loader>
                </div>
              </div>
            </div>
          </div>
        </div>                
      </div>
    </div>
    <div class="col lastest-page-m mb-5 mr-5 pt-0" v-if="!isMobile">
      <div class="container">
        <div class="row text-right">
          <div class="col-lg-12 col-md-4 col-sm-4">
          <a class="text-decoration-none btn btn-outline-primary" href="/jobs" target="_blank"> View all jobs</a>
          </div>
        </div>
      </div>
    </div>
    
    <div class="blog-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img loading="lazy" :src="$S3_url+'/Assets/images/logo/Virtual-interview-tips.svg'" :width="isMobile?330:482" :height="isMobile?178.66:261" alt="Tips to create an impressive video profile">
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col blog-text">
                <span style="font-size:22px !important;font-weight:bold !important;">Video interview tips</span>
                <p>Tips to create an impressive video profile # visume. How to answer interview questions on digital interview</p>
                <p> <a href="https://blog.hireclap.com/" target="noopener" title="video-interview" alt="video interview tips" class="btn btn-outline-primary">Read blog </a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row gap50"></div> <!--row gap-->
    </div>
    <div class="col">
      <div class="container" v-if="!isMobile">
        <div >
          <div class="col emp-banner">
            <b-card overlay :img-src="$S3_url+'/Assets/images/employer-img.svg'" img-alt="Card Image" text-variant="black">
              <b-card-body>
                <b-row>
                  <b-col md="6">
                    <b-card-text>
                      <h6 class="mb-5">Employer zone</h6>
                    </b-card-text>
                    <b-card-text class="mb-0">
                      <h4>I am looking to hire candidates</h4>
                    </b-card-text>
                    <b-card-text class="text-secondary">
                      Recruit from largest pool of candidate video profiles
                    </b-card-text>
                  </b-col>
                  <b-col md="4" class="employer-btn" >
                    <b-col>
                      <b-button href="/recruit/post-job" variant="primary" style="color:#fff !important">Post a job</b-button>
                    </b-col>
                    <b-col>
                      <b-button href="/recruit/login" variant="outline-primary">Employer login</b-button>
                    </b-col>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>
      <div class="container" v-if="isMobile">
        <div class="row">
          <div class="col emp-banner">
            <b-card tag="article" class="mb-2">
              <b-card-body class="text-center">
                <b-card-text>
                  <h6>Employer zone</h6>
                </b-card-text>
                <b-card-text>
                  <h4>I am looking to hire candidates</h4>
                </b-card-text>
                <b-card-text class="text-secondary">
                  Recruit from largest pool of candidate video profiles
                </b-card-text>
                <b-button href="/recruit/post-job" variant="primary" style="color:#fff !important">Post a job</b-button>
                <b-button href="/recruit/login" class="mt-3" variant="outline-primary">Employer sign in</b-button>
              </b-card-body>
              <br clear="all"/>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div class="col faq">
      <div class="container">
        <div class="faq-wrapper">
        <div class="row mt-6">
          <div class="col text-center">
            <span style="font-size:22px !important;font-weight:bold !important;">Frequently asked questions</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="container">
              <div class="col-lg-12 pl-0 bb">
                <div class="row">
                  <div class="col-8">
                    <p v-b-toggle.collapse-a class="mb-0">How Hireclap helps jobseeker to find jobs?</p>
                  </div>
                  <div class="col-4">
                    <div class="expnd" v-b-toggle.collapse-a>+</div>
                  </div>
                </div>
                <b-collapse id="collapse-a" class="mt-2">
                  <b-card class="txt-secondary">Hireclap.com is a video profile based job portal that helps jobseekers to find the best matching jobs. Jobseekers can post a 2 minute video profile (video resume / visume) , instead of the conventional resume. This video resume is accessible to all active recruiters.</b-card> 
                  <b-card class="txt-secondary">Based on our last year hiring trends, we have observed that candidates with video resume has got a 40% higher shortlisting probability, than a similar candidate without visume.</b-card>
                  <b-card class="txt-secondary">More than 5000 companies are actively hiring from our platform for their entry level fresher hiring roles .Also we are aggregating all leading Govt jobs , private jobs , work from home jobs , internship opportunities, freelance jobs, sales & marketing jobs etc.</b-card> 
                  <b-card class="txt-secondary">Hireclap is now one of the largest video profile based job portal with more than 3 million job seekers registered. </b-card>
                  <b-card class="txt-secondary">Create your Hireclap profile today -with an impressive video profile. All the best.</b-card>
                </b-collapse>
              </div>
           
              <div class="col-lg-12 pl-0 bb">
                <div class="row">
                  <div class="col-8">
                    <p v-b-toggle.collapse-b class="mb-0">How is Visume different from a resume ?</p>
                  </div>
                  <div class="col-4">
                    <div class="expnd" v-b-toggle.collapse-b>+</div>
                  </div>
                </div>
                <b-collapse id="collapse-b" class="mt-2">
                  <b-card class="txt-secondary">Visume is a 2 minute video introduction. You can showcase your uniqueness, skills , abilities, recognitions, experience & why should a company hire you.</b-card>
                  <b-card class="txt-secondary">Visume talks about you - much more than a conventional document based resume.</b-card>
                  <b-card class="txt-secondary">For companies - visume based shortlisting is a convenient way to find best matching candidates virtually, without meeting you. It's a transparent , effective way to hire right fit candidates for companies.</b-card>
                </b-collapse>
               </div>

              <div class="col-lg-12 pl-0 bb">
                <div class="row">
                  <div class="col-8">
                    <p v-b-toggle.collapse-c class="mb-0">Tips to create best video profile for a good first impression</p>
                  </div>
                  <div class="col-4">
                    <div class="expnd" v-b-toggle.collapse-c>+</div>
                  </div>
                </div>
                <b-collapse id="collapse-c" class="mt-2">
                  <b-card class="txt-secondary">In the present scenario, recruiters prefer video resumes and because of the pandemic, it has become all the more important to keep one handy.With the advent of new technologies, HRs now expect something more while hiring.Apart from your typical word or pdf resume, they are also looking forward to knowing your personality beyond your resume.</b-card>
                    <b-card class="txt-secondary">The must-have points to include in an impressive video resume are
                      <ul>
                        <li>A nice introduction</li>
                        <li>Education background</li>
                        <li>Brief about your prior work experience/internships</li>
                        <li>Your skills</li>
                        <li>Achievement / Awards / Recognitions</li>
                        <li>Sign off (Conclusion)</li>
                      </ul>
                    </b-card>
                  <b-card class="txt-secondary">Register and access your profile page for more tips, sample videos on how to create an attractive 2 minute video profile introduction.Your face is the best interface.Update your video profile & create a good first impression among recruiters.</b-card>
                </b-collapse>
              </div>
              
              <!-- <div class="col-lg-12 pl-0 bb">
                <div class="row">
                  <div class="col-8">
                    <p v-b-toggle.collapse-d class="mb-0">What type of job opportunities are posted on Hireclap ?</p>
                  </div>
                  <div class="col-4">
                    <div class="expnd" v-b-toggle.collapse-d>+</div>
                  </div>
                </div>
                  <b-collapse id="collapse-d" class="mt-2">
                    <b-card class="txt-secondary">Most of the job postings on Hireclap are for fresher / entry level job opportunities. We are posting experienced level jobs too. </b-card> 
                    <b-card class="txt-secondary">Also we are aggregating relevant jobs for engineering , MBA, IT software, Government jobs , work from home jobs , all types for freelance jobs etc.All sorts of B Tech engineering job opportunities - be it mechanical engineering jobs,Electrical engineering jobs, biotechnology jobs, electrical engineering jobs, MCA Jobs etc. We are posting graduate , post graduate, 12th or 10th pass out jobs. In the field of commerce and accountants, we have CA (chartered accountant) jobs, company secretary jobs. Apart from this we have BBA jobs, supply chain management jobs, diploma jobs etc.</b-card>
                    <b-card class="txt-secondary">You can also find jobs based on popular categories like IT software , core engineering , Online teaching , upskilling opportunities etc .Also jobs are sorted based on popular cities like Jobs from Bangalore, Jobs from Mumbai , Jobs from Chennai , Jobs from Hyderabad, Jobs from Delhi, Jobs from Kolkata , Jobs from Ahmedabad , Jobs from tier 2 & 3 cities etc . Visit the ‘Jobs by cities’ section.We have jobs across all fields including Research, management,call center, BPO, Engineering, medical, testing , technical jobs etc.</b-card>
                    <b-card class="txt-secondary">Visit the 'All jobs' section to access active jobs & fInd the best matching jobs as per your job role, location preferences</b-card>
                  </b-collapse>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modals :open="modals.open"/>
  </section>
</template>

<script>
  const Modals = () => import(/* webpackPrefetch: true */ "@/components/Modals");
  // const VueSlickCarousel = () => import(/* webpackPrefetch: true */ "vue-slick-carousel");

  import '../assets/hire.css';
  // import 'vue-slick-carousel/dist/vue-slick-carousel.css';
  // import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
  import Actions from '../libraries/apiActions.js'
  import validation from '../libraries/validFormat' ;
  // import { mapState, mapGetters } from 'vuex';
  // import { COURSES } from '../store/modules/courses/constants';
  // import { STATES } from '../store/modules/states/constants';
  // import { JOB_ROLE } from '../store/modules/jobrole/constants';
  // import store from '../store';

  var jsonld = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name" : "Hireclap",
    "url": process.env.VUE_APP_URL,
    "logo": "https://d22r5huxahsjvt.cloudfront.net/Assets/images/hireclap-logo-HD.png",
    "contactPoint" : [
    { "@type" : "ContactPoint",
    "telephone" : "+919037156256",
    "contactType" : "Customer Service",
    "areaServed" : "India"
    }],
  };
  var faqJson = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How Hireclap helps jobseeker to find jobs?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "<p>Hireclap.com is a video profile based job portal that helps jobseekers to find the best matching jobs. Jobseekers can post a 2 minute video profile (video resume / visume) , instead of the conventional resume. This video resume is accessible to all active recruiters.</p><p>Based on our last year hiring trends, we have observed that candidates with video resume has got a 40% higher shortlisting probability, than a similar candidate without visume.</p><p>More than 5000 companies are actively hiring from our platform for their entry level fresher hiring roles .Also we are aggregating all leading Govt jobs , private jobs , work from home jobs , internship opportunities, freelance jobs, sales & marketing jobs etc.</p><p>Hireclap is now one of the largest video profile based job portal with more than 3 million job seekers registered.</p><p>Create your Hireclap profile today -with an impressive video profile. All the best.</p>"
      }
    }, {
      "@type": "Question",
      "name": "How is Visume different from a resume?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "<p>Visume is a 2 minute video introduction. You can showcase your uniqueness, skills , abilities, recognitions, experience & why should a company hire you.</p><p>Visume talks about you - much more than a conventional document based resume.</p><p>For companies - visume based shortlisting is a convenient way to find best matching candidates virtually, without meeting you. It's a transparent , effective way to hire right fit candidates for companies.</p>"
      }
    }, {
      "@type": "Question",
      "name": "Tips to create best video profile for a good first impression",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "<p>In the present scenario, recruiters prefer video resumes and because of the pandemic, it has become all the more important to keep one handy.With the advent of new technologies, HRs now expect something more while hiring.Apart from your typical word or pdf resume, they are also looking forward to knowing your personality beyond your resume.</p><p>The must-have points to include in an impressive video resume are</p><ul><li>A nice introduction</li><li>Education background</li><li>Brief about your prior work experience/internships</li><li>Your skills</li><li>Achievement / Awards / Recognitions</li><li>Sign off (Conclusion)</li></ul><p>Register and access your profile page for more tips, sample videos on how to create an attractive 2 minute video profile introduction.Your face is the best interface.Update your video profile & create a good first impression among recruiters.</p>"
      }
    }, {
      "@type": "Question",
      "name": "What type of job opportunities are posted on Hireclap ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "<p>Most of the job postings on Hireclap are for fresher / entry level job opportunities. We are posting experienced level jobs too.</p><p>Also we are aggregating relevant jobs for engineering , MBA, IT software, Government jobs , work from home jobs , all types for freelance jobs etc.All sorts of B Tech engineering job opportunities - be it mechanical engineering jobs,Electrical engineering jobs, biotechnology jobs, electrical engineering jobs, MCA Jobs etc. We are posting graduate , post graduate, 12th or 10th pass out jobs. In the field of commerce and accountants, we have CA (chartered accountant) jobs, company secretary jobs. Apart from this we have BBA jobs, supply chain management jobs, diploma jobs etc.</p><p>You can also find jobs based on popular categories like IT software , core engineering , Online teaching , upskilling opportunities etc .Also jobs are sorted based on popular cities like Jobs from Bangalore, Jobs from Mumbai , Jobs from Chennai , Jobs from Hyderabad, Jobs from Delhi, Jobs from Kolkata , Jobs from Ahmedabad , Jobs from tier 2 & 3 cities etc . Visit the ‘Jobs by cities’ section.We have jobs across all fields including Research, management,call center, BPO, Engineering, medical, testing , technical jobs etc.</p><p>Visit the 'All jobs' section to access active jobs & fInd the best matching jobs as per your job role, location preferences</p>"
      }
    }]
  }
  var breadcrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": window.location.href
    }]
  }
    
export default {
  name: "home",
  components: {
    Modals,
    // VueSlickCarousel
  },
  
  metaInfo: {
    link: [
      { rel: 'canonical', href: process.env.VUE_APP_URL }
    ],
    script: [
      {type: 'application/ld+json', json: jsonld },
      {type: 'application/ld+json', json: faqJson},
      {type: 'application/ld+json', json: breadcrumbJson},
      
    ]
  },
  beforeMount() {
    // /job_listing
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }
  },
  mounted(){
    this.getJobList();
  },
  beforeCreate() {
      // this.$store = store;
  },
  created() {
    // const apiFn = this.action;
    // this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    // this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    // this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
  },
  computed: {
    // ...mapGetters(['cachedDispatch']),
    // ...mapState({
    //   courses: state => state.courses.courses.courses,
    //   locations: state => state.states.locations.locations,
    //   jobrole: state => state.jobrole.jobrole.jobrole
    // })
  },
  data() {
    return {
      action : new Actions(),
      validate: new validation(),
      modals:{ 
        open:'exit',
      },
      // jobrole:[],
      jobs:[],
      // locations:[],
      // courses:[],
      filter:{
        jobrole:null,
        city_id:null,
        course_id:null,
        home_display:1,
        search:null
      },
      isMobile:false,
      jobs_loading: true
    }
  },
  methods:{
    openModal : function(type= ''){
      this.open = type == "" ? this.open : type;
        if (this.open == "contact_us") {
          this.$refs["contact-us"].show();
        }
      this.modals.open = 'exit';
      this.modals.open = type;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    getJobList(){
      console.log('gere');
      this.action.getJobList(this.filter).then((res) => {
        console.log(res);
        this.jobs_loading = false;
        this.jobs = res.jobs.slice(0,10)
        this.jobs.forEach(job => {
          job.last_date = this.validate.convertTimestamp(job.last_date);
          let param = job.job_url?job.job_url:'';
          param = param.replace(/[^A-Z0-9]+/ig, "-");
          if(param.length > 80){
            let param_split = param.split("-");
            let id_length = param_split[param_split.length-1].length;
            param = param.substring(0, 80-(id_length+1));
            param = param+'-'+param_split[param_split.length-1];
          }
          job.param = param;
          // job.date_of_posting = this.validate.timeDifference(job.date_of_posting);
        })
      })
    },

    getJobs(){
      var search_query = {};
      if(this.filter.search){
        search_query.search = this.filter.search
      }
      if(this.filter.course_id){
        search_query.course_id = this.filter.course_id
      }
      if(this.filter.city_id){
        search_query.city_id = this.filter.city_id
      }
      this.$router.push({ path: '/jobsearch', query: search_query })
    },
    msearch_open() {
      document.getElementById("m-search").style.left = "0";
    },

    msearch_close() {
      document.getElementById("m-search").style.left = "-420px";
    },
    openOCES(){
      window.open('https://bit.ly/barchireclap', '_blank').focus();
    }
  },
  watch:{
    }
}
</script>

<style scoped>.card-img-overlay {position: relative;left: 175px;}video {width: 100%;height: auto;}</style>