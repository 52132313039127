export const UPDATES = {
    GET_UPDATE: 'getUpdates'
};

export const UPDATES_MUTATIONS = {
    UPDATES_RESET: 'UPDATESReset',
    UPDATES_PROGRESS: 'updatesInProgress',
    UPDATES_SUCCESS: 'updateUpdates',
    UPDATES_FAILURE: 'updatesFailure'
};
