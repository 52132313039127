import prepareErrorForVuexStore from '../../utils/prepare-error-for-vuex-store';
import initialState from './initial-state';

export function resetState(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialState());
}

export function jobRoleInProgress(state) {
    state.jobrole.__loading__ = true;
    state.jobrole.__error__ = null;
}

export function jobRoleFailure(state, error) {
    state.jobrole.__error__ = prepareErrorForVuexStore(error, 'data for jobrole not provided');
    state.jobrole.__loading__ = false;
}

export function updateJobRole(state, jobrole) {
    state.jobrole = {
        jobrole,
        __error__: null,
        __loading__: false
    };
}