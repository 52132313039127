
import { STATES_MUTATIONS } from './constants';

export async function getStates({ commit },
    { param } = {}) {
    try {
        let locations = [];
        commit(STATES_MUTATIONS.STATES_PROGRESS);
        await param.getStates().then(async (data) => {
            locations.push({header: 'Top Metropolitan Cities'})
            await data.states.forEach(async (element) => {
              await element.cities.forEach(city => {
                if(city.top_city == 1){
                  locations.push({text:city.city_name,id:city.city_id, s_id:city.state_id,group:'Top Metropolitan Cities'})
                }
              });
              locations.push({divider:true})
              locations.push({header: element.state_name});
              element.cities.forEach(city => {
                if(city.top_city == 0){
                  locations.push({text:city.city_name,id:city.city_id, s_id:city.state_id,group:element.state_name})
                }
              });
            });
            locations.push({text:'Anywhere india',id:5000,s_id:36,group:'Anywhere india'})
          });
        commit(STATES_MUTATIONS.STATES_SUCCESS, locations);
    } catch(error) {
        commit(STATES_MUTATIONS.STATES_FAILURE, error);
    }
}