import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/home.vue'
import dashboard from '@/views/dashboard.vue'

const seekerRegister = () => import("@/views/Register.vue");
const siteMap = () => import("@/views/Sitemap.vue");
const seekerJobDescription = () => import("@/views/JobDescription.vue");
const jobs = () => import("@/views/JobList.vue");
const fresher_jobs = () => import("@/views/FresherJobs.vue");
const userSubscription = () => import("@/views/Subscription.vue");
const privacy = () => import("@/views/Privacy.vue");
const contact_us = () => import("@/views/ContactUs.vue");
const forgotPassword = () => import("@/views/ForgotPassword.vue");
const forgotPasswordRequest = () => import("@/views/ForgotPasswordRequest.vue");
const terms = () => import("@/views/Terms.vue");
const sitemapPage = () => import("@/views/SitemapPage.vue");
const pageNotFound = () => import("@/views/pageNotFound.vue");
const redirect = () => import("@/views/Redirect.vue");
const Jobfair = () => import("@/views/Jobfair.vue");
const seeker_login = () => import("@/views/SignIn.vue");
const jobsByUrl = () => import("@/views/JobsByUrl.vue");
const PublicProfile = () =>import("@/views/PublicProfile.vue");
const Questionnaire = () => import("@/views/Questionnaire.vue");
const PrivateJob = () => import("@/views/PrivateJob.vue");
const PrivateJobForm = () => import("@/views/PrivateJobApplicationForm.vue");
const seekerPost = () => import("@/views/Post.vue");
const customRedirect = () => import("@/views/CustomRedirects.vue");
const TrainingInstitutes = () => import("@/views/TrainingInstitutes.vue");
const Colleges = () => import("@/views/Colleges.vue");
const InstituteDetails = () => import("@/views/InstituteDetails.vue");
const CollegeDetails = () => import("@/views/CollegeDetails.vue");
const CourseDetails = () => import("@/views/CourseDetails.vue");
const TrainingCourses = () => import("@/views/TrainingCourses.vue");
const InstituteCorporate = () => import("@/views/InstituteCorporate.vue");

const interviewQuestionCss = () =>import("@/views/Blogs/interviewQuestionCss.vue");
const declarationInResume = () =>import("@/views/Blogs/declarationInResume.vue");
const areaOfInterestInResume = () =>import("@/views/Blogs/areaOfInterestInResume.vue");

import VueCookies from 'vue-cookies'

/* Vue Routes*/

const routes = [
    {
        path: '/',
        name: 'home',
        component: home,
        meta: {
                title: 'Search jobs online | Upload Video Resume | Hireclap.com',
                metaTags:[
                  {name: 'description',content: 'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
                  {name:'keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
                  // {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
                  // {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
                  // {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
                ],
                header:1
        },
    },
    {
      path: '/al',
      name: 'redirect',
      component: redirect,
      meta: { title: 'Hireclap',header:1 }
    },
    {
      path: '/jobseeker/reset_password',
      name: 'forgot_password',
      component: forgotPassword,
      meta: { title: 'Reset Password',
      metaTags:[
        // {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        // {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        // {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
      ],header:1}
    },
    {
      path: '/jobseeker/request_password',
      name: 'forgot_password_request',
      component: forgotPasswordRequest,
      meta: { title: 'Request Password',
      metaTags:[
        // {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        // {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        // {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
      ],header:1}
    },
    {
      path: '/myprofile',
      name: 'dashboard',
      component: dashboard,
      
      // get component(){
      //   if(window.$cookies.get("hire-seeker")){
      //     return dashboard
      //   }else{
      //     return home
      //   }
      // },
      meta: { title: 'Jobseeker My profile - Hireclap',
              metaTags:[
                {name:'description',content:'Job seeker profile with video resume , contact details , personal details , experience,settings  & recommended jobs list '},
                {name:'keywords',content:'Job seeker profile, Recommended, jobs, applied jobs'},
                // {property: 'og:title', content: 'Jobseeker My profile - Hireclap'},
                // {property: 'og:description', content: 'Job seeker profile with video resume , contact details , personal details , experience,settings  & recommended jobs list '},
              ],header:1}
    },
    {
      path: '/jobseeker/register',
      name: 'SeekerReg',
      props: true,
      get component(){
        if(window.$cookies.get("hire-seeker")){
          return dashboard
        }else{
          return seekerRegister
        }
      },
      meta: { title: 'Job seeker registration-Upload video resume-Find jobs - Hireclap',
              metaTags:[
                {name:'description',content:'Job seeker registration page.Register your profile, upload 2 minute video profile and find matching jobs .Apply for fresher entry level jobs'},
                {name:'keywords',content:'Job seeker, registration . video , resume, apply , jobs'},
                // {property: 'og:title', content: 'Job seeker registration-Upload video resume-Find jobs - Hireclap'},
                // {property: 'og:description', content: 'Job seeker registration page.Register your profile, upload 2 minute video profile and find matching jobs .Apply for fresher entry level jobs'},
              ],header:1}
    },
    {
      path: '/candidate/:user',
      name: 'Public-profile',
      props: true,
      component: PublicProfile,
      meta: { title: 'Job seeker registration-Upload video resume-Find jobs - Hireclap',
      metaTags:[
        {name:'description',content:'Job seeker registration page.Register your profile, upload 2 minute video profile and find matching jobs .Apply for fresher entry level jobs'},
        {name:'keywords',content:'Job seeker, registration . video , resume, apply , jobs'},
        // {property: 'og:title', content: 'Job seeker registration-Upload video resume-Find jobs - Hireclap'},
        // {property: 'og:description', content: 'Job seeker registration page.Register your profile, upload 2 minute video profile and find matching jobs .Apply for fresher entry level jobs'},
      ],header:1}
    },
    {
      path: '/jobseeker/login',
      name:'JobseekerSign',
      props: true,
      get component(){
        if(window.$cookies.get("hire-seeker")){
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const src = urlParams.get('src')
            if(src == 'jobfair') {
              window.location.href = urlParams.get('redirect_from')
            }
            return dashboard;
        }else{
          return seeker_login

        }
      },
      meta: { title: 'Job seeker  signin-Upload video resume-Find jobs - Hireclap',
      metaTags:[
        {name:'description',content:'Job seeker login page.Login your profile, upload 2 minute video profile and find matching jobs .Apply for fresher entry level jobs'},
        {name:'keywords',content:'Job seeker, Signin . video , resume, apply , jobs'},
        // {property: 'og:title', content: 'Job seeker login-Upload video resume-Find jobs - Hireclap'},
        // {property: 'og:description', content: 'Job seeker login page.Login your profile, upload 2 minute video profile and find matching jobs .Apply for fresher entry level jobs'},
      ],header:1}
    },
    {
      path: '/jobfair',
      name: 'Jobfair',
      component: Jobfair,
      meta: { title: 'Virtual Jobfair',header:1},
      props: true 
    },
    {
      path:'/site-map',
      name: 'siteMap',
      component: siteMap,
      meta:{
        title: 'Hireclap',
        header:1
      }
    },
    {
      path:'/privacy',
      name: 'privacy',
      component: privacy,
      meta:{
        title: 'Hireclap',
        header:1
      }
    },
    {
      path:'/contact-us',
      name: 'contact_us',
      component: contact_us,
      meta: { title: 'Contact us - Hireclap',
      metaTags:[
        {name:'description',content:'Contact us page to submit comments, queries, and questions, etc about Hireclap.com'},
        {name:'keywords',content:'Job seeker, contact us submit comments, queries, questions'},
        {property: 'og:title', content: 'Contact us - Hireclap'},
        {property: 'og:type', content: 'website'},
        {property: 'og:url', content: window.location.href},
        {property: 'og:description', content: 'Contact us page to submit comments, queries, and questions, etc about Hireclap.com'},
        {property: 'og:image', content: 'https://d22r5huxahsjvt.cloudfront.net/Assets/images/hireclap-logo-HD.png'},
        {property: 'og:site_name', content: 'hireclap.com'},
      ],header:1},
      gtm: 'Contact Us'
    },
    {
      path:'/terms',
      name: 'terms',
      component: terms,
      meta:{
        title: 'Hireclap',
        header:1
      }
    },
    {
      path:'/training-institutes',
      name: 'TrainingInstitutes',
      component: TrainingInstitutes,
      meta: {
        title: 'Training Institutes | Hireclap.com',
          metaTags:[
            {name: 'description',content: 'Discover institutes offering a variety of courses. Find the right institute for your skill enhancement needs.'},
            {name:'keywords',content:'Institutes, Training Centers, Courses, Skill Enhancement, Institutes List'},
          ],
        header:1,
      },
    },
    {
      path:'/view-colleges',
      name: 'Colleges',
      component: Colleges,
      meta: {
        title: 'Colleges | Hireclap.com',
          metaTags:[
            {name: 'description',content: 'Discover colleges offering a variety of courses. Find the right college for your skill enhancement needs.'},
            {name:'keywords',content:'colleges, , Courses, Skill Enhancement, colleges List'},
          ],
        header:1,
      },
    },
    {
      path:'/training-institutes/:id',
      name: 'InstituteDetails',
      component: InstituteDetails,
      props: true,
      meta: {
        title: 'Institute Details | Hireclap.com',
        metaTags:[
          {name: 'description',content: 'Explore details about institute. Learn about courses offered, facilities, and contact information.'},
          {name:'keywords',content:'Institute Details, Training Institute, Course Offerings, Facilities, Contact Information, Skill Development'},
        ],
        header:1
      },
    },
    {
      path:'/view-colleges/:id',
      name: 'CollegeDetails',
      component: CollegeDetails,
      props: true,
      meta: {
        title: 'College Details | Hireclap.com',
        metaTags:[
          {name: 'description',content: 'Explore details about college. Learn about courses offered, facilities, and contact information.'},
          {name:'keywords',content:'College Details, Course Offerings, Facilities, Contact Information, Skill Development'},
        ],
        header:1
      },
    },
    {
      path:'/training-courses/:id',
      name: 'CourseDetails',
      component: CourseDetails,
      props: true,
      meta: {
        title: 'Course Details | Hireclap.com',
         metaTags:[
          {name: 'description',content: 'Get detailed information about a specific training course. Learn about course content, instructors, and enrollment options.'},
          {name:'keywords',content:'Course Details, Training Course, Course Information, Curriculum, Instructors, Enrollment'},
        ],
        header:1
      },
    },
    {
      path:'/training-courses',
      name: 'TrainingCourses',
      component: TrainingCourses,
      meta: {
        title: 'Training Courses | Hireclap.com',
        metaTags:[
          {name: 'description',content: 'Explore a wide range of training courses to enhance your skills. Find courses in various domains and industries.'},
          {name:'keywords',content:'Training Courses, Skill Enhancement, Course Catalog, Skill Development, Courses, Professional Training'},
        ],
        header:1
      },
    },
    {
      path:'/skillhire',
      name: 'InstituteCorporate',
      component: InstituteCorporate,
      meta: {
        title: 'Institutes | Hireclap.com',
          metaTags:[
            {name: 'description',content: 'Discover institutes to hire skilled professionals. Browse through a list of institutes offering courses in various skills.'},
            {name:'keywords',content:'Institutes, Training, Hiring, Professional Skills, Courses, Employers, Skill Training, Skilled Professionals'},
          ]
      },
      
    },
    {
      path: '/jobs',
      name: 'JobList',
      component: jobs,
      // meta: { title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
      //         metaTags:[
      //           {name:'description',content:'Entry Level Jobs Search.Latest Jobs for Freshers In India. Government, IT software,bank , sales, MNC , work from home job vacancies'},
      //           {name:'keywords',content:'Jobs,Entry Level jobs, freshers opening, IT, Software, Core Technical ,Government , Defence , Research , Internship, BPO, MBA, Teaching, Pharma, StartUp, Diploma , BBM, BCA , BSc, Scholarships, Dream, Walkin , 1 to 3 Yr Exp jobs,jobs in Bangalore, pune, mumbai,Kolkata, Chennai, Delhi,  B.tech freshers job, freshers IT jobs, Btech,MBA,mechanical, electrical, engineering jobs'},
      //           // {property: 'og:title', content: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap'},
      //           // {property: 'og:url', content: window.location.href},
      //           // {property: 'og:description', content: 'Entry Level Jobs Search.Latest Jobs for Freshers In India. Government, IT software,bank , sales, MNC , work from home job vacancies'},
      //         ]
      // }
      meta:{
        header:1
      }
    },
    {
      path: '/jobs-for-freshers',
      name: 'fresher_job_list',
      component: fresher_jobs,
      meta: { title: 'Fresher jobs in India | Jobs For Freshers in India | Latest Jobs for Freshers in India',
              metaTags:[
                {name:'description',content:'Jobs For Freshers: Search & Apply for the Latest Fresher Jobs for Graduates & Post Graduates. New Job Postings Everyday. India\'s Best Job Site'},
                {name:'keywords',content:'Jobs,Entry Level jobs, freshers opening, IT, Software, Core Technical ,Government , Defence , Research , Internship, BPO, MBA, Teaching, Pharma, StartUp, Diploma , BBM, BCA , BSc, Scholarships, Dream, Walkin , 1 to 3 Yr Exp jobs,jobs in Bangalore, pune, mumbai,Kolkata, Chennai, Delhi,  B.tech freshers job, freshers IT jobs, Btech,MBA,mechanical, electrical, engineering jobs'},
                // {property: 'og:title', content: 'Fresher jobs in India | Jobs For Freshers in India | Latest Jobs for Freshers in India'},
                // {property: 'og:description', content: 'Jobs For Freshers: Search & Apply for the Latest Fresher Jobs for Graduates & Post Graduates. New Job Postings Everyday. India\'s Best Job Site'},
              ],
              header:1
      }
    },
    {
      path: '/not-found',
      name: 'page-Not-Found',
      component: pageNotFound,
      meta: { 
        title: 'Page not found - Hireclap',
        metaTags:[
              {name:'robots',content:'noindex'}
            ],
            header:1
      }
    },
    {
      path: '/jobs-by-courses',
      name: 'Jobs-by-courses',
      component: jobsByUrl,
      meta: {
        title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
        metaTags:[
          // {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
          // {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
          // {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        ],
        header:1
      },
      props: true 
    },
    {
      path: '/jobs-by-cities',
      name: 'Jobs-by-cities',
      component: jobsByUrl,
      meta: {
        title: 'Jobs in City | Latest Job Vacancies in City for freshers',
        metaTags:[
          // {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
          // {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
          // {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        ],
        header:1
      },
      metaTags:[
        {name:'description',content:'Find Latest Job openings in City ✓Job Vacancy in City ✓Jobs opportunities in City for freshers. Apply Now',
        metaTags:[
          // {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
          // {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
          // {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        ],
        header:1
      },
      ],
      props: true 
    },
    {
      path: '/jobs-by-roles',
      name: 'Jobs-by-roles',
      component: jobsByUrl,
      meta: {
        title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
        // metaTags:[
        //   {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        //   {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        //   {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        // ]
        header:1
      },
      props: true 
    },
    {
      path: '/jobs-by-categories',
      name: 'Jobs-by-categories',
      component: jobsByUrl,
      meta: {
        title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
        // metaTags:[
        //   {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        //   {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        //   {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        // ]
        header:1
      },
      props: true 
    },
    {
      path: '/jobs-by-company',
      name: 'Jobs-by-company',
      component: jobsByUrl,
      meta: {
        title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
        // metaTags:[
        //   {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        //   {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        //   {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        // ]
        header:1
      },
      props: true 
    },
    {
      path: '/jobs-by-skills',
      name: 'Jobs-by-skills',
      component: jobsByUrl,
      meta: {
        title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
        // metaTags:[
        //   {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        //   {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        //   {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        // ]
        header:1
      },
      props: true 
    },
    {
      path: '/jobs-by-branches',
      name: 'Jobs-by-branches',
      component: jobsByUrl,
      meta: {
        title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
        // metaTags:[
        //   {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        //   {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        //   {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        // ]
        header:1
      },
      props: true 
    },
    {
      path: '/fresher-jobs-in-cities',
      name: 'Fresher-jobs-in-cities',
      component: jobsByUrl,
      meta: {
        title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
        // metaTags:[
        //   {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        //   {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        //   {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        // ]
        header:1
      },
      props: true 
    },
    {
      path:'/blog/css-interview-questions-and-answers',
      name: 'css-interview_question',
      component: interviewQuestionCss,
      meta:{
        title: 'CSS Interview Questions: Master CSS with Over 100 Interview Questions & Answers',
        metaTags:[
          {name:'description',content:'CSS Interview Questions - Level up your CSS skills with our comprehensive guide! Master CSS techniques, learn from 100+ interview questions, and gain valuable answers to ace your next web development interview.'},
        ]
      }
    },
    {
      path:'/blog/writing-a-declaration-in-resume',
      name: 'writing-declaration-in-resume',
      component: declarationInResume,
      meta:{
        title: 'Writing a Declaration in Resume: A Step-by-Step Guide - Hireclap',
        metaTags:[
          {name:'description',content:'Declaration in Resume - Enhance your resume with a powerful declaration! Learn about its components, importance, winning strategies, and common mistakes to make an impact on potential employers.'},
        ]
      }
    },
    {
      path:'/blog/area-of-interest-on-your-resume',
      name: 'area-of-interest-in-resume',
      component: areaOfInterestInResume,
      meta:{
        title: 'Area of Interest on Your Resume: Complete Guide 2023 - Hireclap',
        metaTags:[
          {name:'description',content:'Area of Interest - Highlight your personality and stand out with our comprehensive guide on Area of Interest on your resume. Discover 30+ hobbies and interests, along with essential dos and donts to ensure a compelling and professional representation.'},
        ]
      }
    },
    {
      path: '/questionnaire/:id',
      name: 'Questionnaire',
      component: Questionnaire,
      meta:{
        header:1
      },
      props: true 
    },
    {
      path: '/sitemap',  
      name: 'SitemapPage',
      component: sitemapPage,
      meta: { title: 'Sitemap - Hireclap',
      metaTags:[
        {name:'description',content:'Sitemap for Hireclap.Jobs by location, jobs by category, job roles, major companies hiring , latest job vacancies'},
        {name:'keywords',content:'Sitemap, Hireclap, Jobs by location , jobseeker, Employer, jobs by categy '},
        // {property: 'og:title', content: 'Sitemap - Hireclap'},
        // {property: 'og:description', content: 'Sitemap for Hireclap.Jobs by location, jobs by category, job roles, major companies hiring , latest job vacancies'},
      ],header:1
      }
    },
      {
        path: '/job/:private_job',
        name: 'private_job',
        component: PrivateJob,
        meta:{header:2}
      },
    {
      path: '/form/:private_job',
      name: 'private_job_form',
      component: PrivateJobForm,
      meta:{header:2}
    },
    {
      path: '/:jobs_list',
      name: 'jobs_list',
      component: jobs,
      // meta: { title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
      // ],
      // metaTags:[
      //   {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
      //   {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
      //   {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
      // ]
    // },
      meta:{header:1},
      props: true 
    },
    {
      path: '/jobsearch',
      name: 'job_search',
      component: jobs,
      // meta: { title: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap',
      // metaTags:[
      //   {name:'description',content:'Entry Level Jobs Search.Latest Jobs for Freshers In India. Government, IT software,bank , sales, MNC , work from home job vacancies'},
      //   {name:'keywords',content:'Jobs,Entry Level jobs, freshers opening, IT, Software, Core Technical ,Government , Defence , Research , Internship, BPO, MBA, Teaching, Pharma, StartUp, Diploma , BBM, BCA , BSc, Scholarships, Dream, Walkin , 1 to 3 Yr Exp jobs,jobs in Bangalore, pune, mumbai,Kolkata, Chennai, Delhi,  B.tech freshers job, freshers IT jobs, Btech,MBA,mechanical, electrical, engineering jobs'},
        // {property: 'og:title', content: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap'},
        // {property: 'og:description', content: 'Entry Level Jobs Search.Latest Jobs for Freshers In India. Government, IT software,bank , sales, MNC , work from home job vacancies'},
//       ]
      meta:{header:1}
// }
    },
    {
      path: '/jobs/:job',
      name: 'seeker_job_description',
      component: seekerJobDescription,
      meta: { 
        title: window.location.href.split('/')[window.location.href.split('/').length-1].replace(/-/g,' '),
        // metaTags:[
        //   {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        //   {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        //   {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        // ]
        header:1
      },
      props: true 
    },
    {
      //custom redirect for axis link
      path: '/post/kickstart-your-career-at-axis-bank-10',
      beforeEnter() {
        window.location.href = 'https://learnforjobs.niit.com/india/graduates/banking-and-finance/axis-bank-young-bankers-program-landing-page?utm_source=Hireclap&utm_medium=emailer&utm_campaign=Hireclap_BRO';
      },
    },
    {
      //custom redirect for manipal link
      path: '/post/kickstart-your-career-as-deputy-manager-it-8',
      beforeEnter() {
        window.location.href = 'https://placements.manipalskills.com/axisjava.html?utm_source=Hireclap&utm_medium=Publishers&utm_campaign=March+2024';
      },
    },

    {
      path: '/custom/:custom_link',
      name: 'custom_redirect',
      component: customRedirect,
      meta: { 
        header:1
      },
      props: true 
    },
    {
      path: '/post/:post',
      name: 'post',
      component: seekerPost,
      meta: { 
        title: window.location.href.split('/')[window.location.href.split('/').length-1].replace(/-/g,' '),
        // metaTags:[
        //   {property:'og:title',content:'Upload your Video resume - Search for fresher Jobs - Hireclap'},
        //   {property:'og:description',content:'Upload your video resume & find matching entry level jobs. Search and apply for the job vacancies in Government sector, IT, software, technical, banking, sales,BPO jobs etc'},
        //   {property:'og:keywords',content:'Job,Video Profile,recruitment,Resume, Entry Level, freshers jobs, government jobs,bank jobs, railway, recruitment,online ,part time, Engineering, management , technical, BPO, Finance,Accounting Jobs, campus hiring, work from home '},
        // ]
       header:1
      },
      
      props: true 
    },
    
    {
      path: '/unsubscribe',
      name: 'unsubscribe',
      component: userSubscription,
      meta: { title: 'Hireclap',header:1},
      props: true 
    },
    
    {
      path: '/:catchAll(.*)',
      name: 'pageNotFound',
      component: pageNotFound,
      meta: { 
        title: 'Page not found - Hireclap',
        metaTags:[
              {name:'robots',content:'noindex'}
            ],
            header:1
      }
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: pageNotFound,
      meta: { 
        title: 'Page not found - Hireclap',
        metaTags:[
              {name:'robots',content:'noindex'}
            ],
            header:1
      }
    },
]

Vue.use(VueCookies);

const router = new VueRouter({
    mode: 'history', //to remove # from url
    routes: routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if(!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    // tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.querySelector("[property = 'og:locale']").after(tag));

  next();
})

export default router;