
import { BRANCHES_MUTATIONS } from './constants';

export async function getBranches({ commit },
    { param } = {}) {
    try {
        let branchList = [];
        commit(BRANCHES_MUTATIONS.BRANCHES_PROGRESS);
        await param.getSpec().then(async(data) => {
            data.specifications.forEach((element) => {
              branchList.push({
                id: element.bid,
                text: element.branch_name,
              });
            });
          });
        commit(BRANCHES_MUTATIONS.BRANCHES_SUCCESS, branchList);
    } catch(error) {
        commit(BRANCHES_MUTATIONS.BRANCHES_FAILURE, error);
    }
}