import * as actions from './actions';
import * as mutations from './mutations';
import initialState from './initial-state';

export const namespaced = true;
export default {
    actions,
    mutations,
    state: initialState()
};
