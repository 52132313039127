import apiCom from '../src/libraries/apiCom';
const seeker_token = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9zdGF0aWNfYXV0aCIsImlhdCI6MTYyMDYzMTA3NSwiZXhwIjo5OTAxOTE5OTE1NSwibmJmIjoxNjIwNjMxMDc1LCJqdGkiOiI1WVQwTXVoM0Zid0ZoY0RlIiwic3ViIjo0MDA4LCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.lBXcvw3IPM7XwXIrateRV4gIkjypGurV3h4UUkBUNgk'


class AwsUpload {
    constructor() {}

    async uploadToS3(file, filename){
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const base64Data = reader.result.split(',')[1]; // Remove data URL prefix

                const textEncoder = new TextEncoder();
                const dataBytes = textEncoder.encode(base64Data);
                const fileSizeInBytes = dataBytes.byteLength;
                if(fileSizeInBytes < 29000000) {
                    try {
                        const response = await new apiCom('seeker', '/aws-upload').post({ file: base64Data, filename }, { Authorization: seeker_token });
                        resolve(response);
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    reject('File exceeds size limit');
                }
                
                reader.onerror = (error) => {
                    reject(error);
                }
            }
        })
    }
    async uploadImage(file, filename) {
        if (file.size < 20000000) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = async () => {
                    try {
                        const pdfBlob = reader.result
                        const data = { file: pdfBlob, filename: filename};
                        console.log(pdfBlob);
                        const response = await new apiCom('seeker', '/aws-image-upload').post(data, { Authorization: seeker_token });
                        resolve(response);
                    } catch (error) {
                        reject(error);
                    }
                };
    
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        } else {
            return 'File is large';
        }
    }
}


export default AwsUpload;
