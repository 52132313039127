/**
 * Common class to communicate with internal hire22 api's
 */
class Communicator {
    /**
     * Initialize default settings
     * @param {STRING} apiType 
     * @param {STRING} url 
     * @param {STRING} contentType
     */
    constructor(apiType = '', url = '', contentType='') {
        this.type = apiType;
        this.uri = url;
        this.content_type = contentType;
        this.failedStatus = { success: false, message: 'Failed to fetch data' };
        this.defHeaders();
        this.validateURL();
    }

    /**
     * Basic get operation
     * @param {object} headers 
     * @returns {object}
     */
    async get(headers) {
        this.setHeaders(headers);
        this.method = 'get';
        this.response = this.do();
        return this.response;
    }

    /**
     * Basic Post operation
     * @param {object} data 
     * @param {object} headers 
     * @returns {object}
     */
    async post(data, headers) {
        this.setHeaders(headers);
        this.data = data || {};
        this.method = 'post';
        this.response = this.do();
        return this.response;
    }

    /**
     * Basic delete operation
     * @param {object} data 
     * @param {object} headers 
     * @returns {object}
     */
    async delete(headers) {
      this.setHeaders(headers);
      this.method = 'delete';
      this.response = this.do();
      return this.response;
  }
    /**
     * Validate uri is not empty
     */
    validateURL() {
        if (this.url == '')
            throw 'API URI not given. Aborting';
        else if(this.type == 'temp')
            this.url = process.env.VUE_APP_TMP_URL + this.uri;
        else if(this.type == 'employer')
            this.url = process.env.VUE_APP_EMPLOYER_URL + this.uri;
        else if(this.type == 'seeker')
            this.url = process.env.VUE_APP_JOBSEEKER_URL + this.uri;
        else if(this.type == 'admin')
            this.url = process.env.VUE_APP_ADMIN_URL + this.uri;
        else if(this.type == 'admin_temp')
            this.url = process.env.VUE_APP_ADMIN_TMP_URL + this.uri;
        else
            this.url = process.env.VUE_APP_STATIC_URL + this.uri;
    }

    /**
     * Add default headers to header pool
     */
    defHeaders() {
        // let signature = '';

        switch (this.type) {
            case 'temp':
              break;
            case 'employer':
                // signature = process.env.VUE_APP_HIRE22_STATIC_TOKEN;
                break;
            case 'seeker':
              // signature = process.env.VUE_APP_HIRE22_STATIC_TOKEN;
                break;
            case 'static':
              break;
            case 'admin':
              break;
            case  'admin_temp':
              break;
            default:
                throw 'Unknown API call type. Aborting';
        }
        if(this.content_type == 'formData'){
          this.headers = {
            // 'x-hire22-signature': signature,
            
              'Content-Type': 'multipart/form-data',
        };
        }else{
          this.headers = {
            // 'x-hire22-signature': signature,
              'Content-Type': 'application/json',
        };
        }
    }

    /**
     * Add extra headers if present to header pool
     * @param {object} header 
     */
    setHeaders(header) {
      if (typeof header == 'object' && Object.keys(header).length > 0) {
        for (const key in header) {
          this.headers[key] = header[key];
        }
      } 
    }

    /**
     * Do fetch operation based on called method
     * @returns {object}
     */
    async do() {
        let options = {
            method: this.method,
            headers: this.headers,
        };


        if (typeof this.data == 'object' && Object.keys(this.data).length > 0 && this.method != 'get'){
          if(this.content_type == ''){
            options.body = JSON.stringify(this.data);
          }else{
            console.log('thi data' , this.data);
            options.body = this.data;
          }
        } else if (this.content_type == 'formData' && this.data instanceof FormData) {
          options.body = this.data;
        }

        const response = await fetch(this.url, options);

        if (response.ok)
            return response.json()
        throw this.failedStatus.message + ' from ' + this.uri;
    }
}

export default Communicator