import prepareErrorForVuexStore from '../../utils/prepare-error-for-vuex-store';
import initialState from './initial-state';

export function resetState(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialState());
}

export function statesInProgress(state) {
    state.locations.__loading__ = true;
    state.locations.__error__ = null;
}

export function statesFailure(state, error) {
    state.locations.__error__ = prepareErrorForVuexStore(error, 'data for locations not provided');
    state.locations.__loading__ = false;
}

export function updateStates(state, locations) {
    state.locations = {
        locations,
        __error__: null,
        __loading__: false
    };
}