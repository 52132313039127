class validation {
    constructor() {
        /**
 * Initialize default settings
 */
        this.response = {};
    }
    /**
     * Email Validation
     * @param {STRING} data 
     * @param {STRING} field_name 
     */

    checkIfEmpty(data, field_name, msg) {
        if ((typeof data != 'undefined' && (data != '' && data != null)) && (typeof field_name != 'undefined' && field_name != '')) {
          this.response[field_name] =  { val: true, msg: '' };
          return this.response[field_name];
        }
        if (typeof field_name != 'undefined' && field_name != '') {
            this.response[field_name] = { val: false, msg: msg};   
            return this.response[field_name];
        }
        this.response[field_name] = { val: false, msg: msg};
        return this.response[field_name];
    }


    emailValidation(data, field_name = 'email') {
        if (this.checkIfEmpty(data, field_name, 'Enter valid email').val) {
            var reg = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
            this.response[field_name] = reg.test(data) ? { val: true, msg: '' } : { val: false, msg: 'Enter valid email' };
            return this.response[field_name]
        }
        return this.response[field_name]
    }

    matchPassword(data1, data2) {
        this.response.matching = data1 === data2 ? { val: true , msg: ''} : { val: false, msg: 'Password and confirm password should be same'};
        return this.response.matching;
    }
    
    passwordValidation(data, field_name = 'password') {
        if (this.checkIfEmpty(data, field_name, 'Enter minimum 6 characters valid password').val) {
            this.response[field_name] = data.length >= 6 ? { val: true, msg: '' } : { val: false, msg: 'Enter minimum 6 characters valid password' };
            return this.response[field_name]
        }
        return this.response[field_name]
    }

    percentageValidation(data, field_name = 'percentage') {
      if (this.checkIfEmpty(data, field_name, 'Enter valid percentage').val) {
          this.response[field_name] = (parseInt(data) <= 100 && parseInt(data) >=0 ) ? { val: true, msg: '' } : { val: false, msg: 'Enter valid percentage' };
          return this.response[field_name]
      }
      return this.response[field_name]
    }

    textValidation(data, field_name ) {
        if(this.checkIfEmpty(data, field_name, 'Enter valid Name').val){
            var reg = /[^a-zA-Z ]\.?[^a-zA-Z ]/g;
            this.response[field_name] = (data.length > 60 || this.htmlTagCheck(data) || reg.test(data)) ? { val: false, msg: 'Enter valid name' } : { val: true, msg: '' };
            return this.response[field_name]
        }
        return this.response[field_name]
    }

    mobileValidation(data, field_name = 'tel') {
        if(this.checkIfEmpty(data, field_name, 'Enter 10 digit valid mobile number').val){
            var reg = /\D/g;
            this.response[field_name] = (data.length != 10 || this.htmlTagCheck(data) || reg.test(data)) ? { val: false, msg:'Enter 10 digit valid mobile number'  }:{ val: true, msg: '' };
            return this.response[field_name]
        }
        return this.response[field_name]
    }

    jobroleValidation(data, field_name = 'jobrole') {
        if(this.checkIfEmpty(data, field_name, 'Select minimum 1 preferred job role').val){
            this.response[field_name] = (data.length >3 ) ? { val: false, msg:'Select maximum 3 preferred job role'  }:{ val: true, msg: '' };
            return this.response[field_name]
        }
        return this.response[field_name]
    }
    languageValidation(data, field_name = 'language') {
      if(this.checkIfEmpty(data, field_name, 'Select minimum 1 language known').val){
          this.response[field_name] = (data.length >4 ) ? { val: false, msg:'Select maximum 4 language known'  }:{ val: true, msg: '' };
          return this.response[field_name]
      }
      return this.response[field_name]
  }

    htmlTagCheck(data) {
        var res;
        var reg = /(<([^>]+)>)/gi
        res = reg.test(data) ? true : false;
        return res;
    }
    convertTimestamp(data){
      var months = {'01':'Jan', '02':'Feb' , '03':'Mar' , '04':'Apr' , '05':'May' , '06':'June' , '07':'July' , '08':'Aug' , '09':'Sep' , '10':'Oct' , '11':'Nov' , '12':'Dec' , };
      var post_ms = data*1000
      var date_ob = new Date(post_ms)
      var year = date_ob.getFullYear();
      var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      var date = ("0" + date_ob.getDate()).slice(-2);
      var result = date+'-'+months[month]+'-'+year;
      return result;
    }
    timeDifference(data){
      let date1 = new Date()
      let date2 = new Date(data*1000)
      var year_diff = date2.getFullYear()-date1.getFullYear();
      var month_diff = Math.abs(date2.getMonth() - date1.getMonth());
      var days_diff = Math.abs(date2.getDate() - date1.getDate());
      var hour_diff = Math.abs(date2.getHours() - date1.getHours());
      var min_diff = Math.abs(date2.getMinutes() - date1.getMinutes())
      console.log(year_diff);
      if(year_diff != 0){
        let res = (year_diff == 1)?year_diff+' year ago': year_diff+' years ago';
        return res;
      }
      if(month_diff != 0){
        let res = (month_diff==1)? month_diff+' month ago':month_diff+ ' months ago';
        return res;
      }
      if(days_diff != 0){
        let res = (days_diff==1)? days_diff+' day ago':days_diff+ ' days ago';
        return res;
      }
      if(hour_diff != 0){
        let res = (hour_diff==1)? hour_diff+' hour ago':hour_diff+ ' hours ago';
        return res;
      }
      if(min_diff != 0){
        let res = (min_diff==1)? min_diff+' minute ago':min_diff+ ' minuts ago';
        return res;
      }
      let res = 'Just now'
      return res;
    }
    convertDate(date){
      console.log(date);
      var dd = ((date[2].trim()).length==1)?'0'+date[2].trim():date[2].trim();
      var mm = ((date[1].trim()).length==1)?'0'+date[1].trim():date[1].trim();
      var c_date = dd+'-'+mm+'-'+date[0];
      console.log(c_date);
      return c_date;
    }
}

export default validation;