import prepareErrorForVuexStore from '../../utils/prepare-error-for-vuex-store';
import initialState from './initial-state';

export function resetState(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialState());
}

export function updatesInProgress(state) {
    state.updates.__loading__ = true;
    state.updates.__error__ = null;
}

export function updatesFailure(state, error) {
    state.updates.__error__ = prepareErrorForVuexStore(error, 'data for updates not provided');
    state.updates.__loading__ = false;
}

export function updateUpdates(state, updates) {
    state.updates = {
        updates,
        __error__: null,
        __loading__: false
    };
}