
import {
  mdiMapMarker,
  mdiLayers,
  mdiFacebook,
  mdiTwitter,
  mdiLinkedin,
  mdiYoutube,
  mdiTune,
  mdiInstagram,
  mdiFilterOutline,
  mdiCircleOutline,
  mdiDelete,
  mdiSchool,
  mdiCheck,
  mdiCurrencyInr,
  mdiEye,
  mdiEyeOff,
  mdiMagnify,
  mdiPlus,
  mdiMinus,
  mdiCrown,
  mdiCalendarRange,
  mdiLink,
  mdiAccountMultiple,
  mdiShopping,
  mdiAccount,
  mdiFile,
  mdiStarCircle,
  mdiEmailOutline,
  mdiDeskphone,
  mdiCashMultiple,
  mdiPencilCircleOutline,
  mdiRadioboxMarked,
 } from '@mdi/js';

export const icons = {
  mapMarker: mdiMapMarker,
  layers: mdiLayers,
  facebook: mdiFacebook,
  twitter: mdiTwitter,
  linkedin: mdiLinkedin,
  youtube: mdiYoutube,
  instagram: mdiInstagram,
  filterOutline: mdiFilterOutline,
  circleOutline: mdiCircleOutline,
  delete: mdiDelete,
  school: mdiSchool,
  check: mdiCheck,
  currencyInr: mdiCurrencyInr,
  eye: mdiEye,
  eyeOff: mdiEyeOff,
  magnify: mdiMagnify,
  plus: mdiPlus,
  minus: mdiMinus,
  crown: mdiCrown,
  calendarRange: mdiCalendarRange,
  link: mdiLink,
  accountMultiple: mdiAccountMultiple,
  shopping: mdiShopping,
  account: mdiAccount,
  file: mdiFile,
  tune: mdiTune,
  starCircle: mdiStarCircle,
  emailOutline: mdiEmailOutline,
  deskphone: mdiDeskphone,
  cashMultiple: mdiCashMultiple,
  radioboxMarked: mdiRadioboxMarked,
  pencilCircleOutline: mdiPencilCircleOutline
};