import prepareErrorForVuexStore from '../../utils/prepare-error-for-vuex-store';
import initialState from './initial-state';

export function resetState(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialState());
}

export function coursesInProgress(state) {
    state.courses.__loading__ = true;
    state.courses.__error__ = null;
}

export function coursesFailure(state, error) {
    state.courses.__error__ = prepareErrorForVuexStore(error, 'data for courses not provided');
    state.courses.__loading__ = false;
}

export function updateCourses(state, courses) {
    state.courses = {
        courses,
        __error__: null,
        __loading__: false
    };
}