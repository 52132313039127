<template>
  <div class="inside-bg">
    <div class="container" style="padding-top:100px">
      <nav area-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>
      </nav>
      <!-- sidebar mobile -->
      <div class="sidebar" id="mySidebar">
        <nav>
          <div class="col-md-12 mb-3"><button class="sidebar-close" @click="mbar_close">&#10006;</button></div>
          <div>
            <ul>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'jobDetails'}" id="v-pills-myjobs-tab jobDetails" data-toggle="pill" @click.prevent="selectTab('jobDetails')" role="tab" aria-controls="v-pills-myjobs" aria-selected="true">My jobs</div></li>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'scheduledInterviews'}" id="v-pills-interviews-tab scheduledInterviews" data-toggle="pill" @click.prevent="selectTab('scheduledInterviews')" role="tab" aria-controls="v-pills-interviews" aria-selected="false">Scheduled interviews</div></li>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'assessments'}" id="v-pills-assessments-tab assessments" data-toggle="pill" @click.prevent="selectTab('assessments')" role="tab" aria-controls="v-pills-interviews" aria-selected="false">Assessments</div></li>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'comments'}" id="v-pills-comments-tab comments" data-toggle="pill" @click.prevent="selectTab('comments')" role="tab" aria-controls="v-pills-comments" aria-selected="false">Rating and Comments</div></li>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'personalDetails'}" id="v-pills-personal-tab personalDetails" data-toggle="pill" @click.prevent="selectTab('personalDetails')" role="tab" aria-controls="v-pills-personal" aria-selected="false">Personal details</div></li>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'educationalDetails'}" id="v-pills-education-tab educationalDetails" data-toggle="pill" @click.prevent="selectTab('educationalDetails')" role="tab" aria-controls="v-pills-education" aria-selected="false">Education</div></li>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'experienceDetails'}" id="v-pills-workexperience-tab experienceDetails" data-toggle="pill" @click.prevent="selectTab('experienceDetails')" role="tab" aria-controls="v-pills-workexperience" aria-selected="false">Work Experience</div></li>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'institutes'}" id="v-pills-institutes-tab institutes" data-toggle="pill" @click.prevent="selectTab('institutes')" role="tab" aria-controls="v-pills-institutes" aria-selected="false">Institutes</div></li>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'profile_viewers'}" id="v-pills-profile_viewers-tab profile_viewers" data-toggle="pill" @click.prevent="selectTab('profile_viewers')" role="tab" aria-controls="v-pills-profile_viewers" aria-selected="false">Profile Viewers</div></li>
              <li><div class="p-4 nav-link" :class="{'active': selectedTab == 'selectSettings'}" id="v-pills-faq-tab selectSettings" data-toggle="pill" @click.prevent="selectTab('selectSettings')" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</div></li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="row" v-on:click="mbar_close">
        <div class="col-lg-9">
          <div class="col shadow rounded-lg detail-box">
            <div class="row content user-banner">
              <!-- <div v-if="user_loading">
                <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="user_loading">
                  <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
                  <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                  <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                  <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                </content-loader>
              </div> -->
              <div class="user-dp text-center">
                <div class="container">
                  <span class="visumeNotification" v-if="!visume"></span>
                  <div @click.prevent="openModal('visume')" class="userDp">
                    <div class="video-input mt-2" v-if="!visume" >
                      <span class="userDummy"></span>
                      <div class="row addVisumeTxt">
                        <div class="col text-center">
                          <img :src="$S3_url+'/Assets/images/add-visume.svg'" width="40" title="add visume" alt="add visume">
                          <br><span> Add visume</span><br>Increase your chances to get hired
                        </div>
                      </div>
                    </div>
                    <div v-show="visume" style="padding:0;">
                      <span class="play-button"></span>
                      <video :key="visume_path" width="210" height="210" class="video-thumbnail" preload="metadata" style="background-color:#4d5b99 !important;"> 
                        <source :src="$S3_bucket_url+visume_path" type="video/mp4" id="visume_thumbnail"/>
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div class="user-details user-details-loading" v-if="user_loading">
                <p class="mb-0 lead user-name gradient" style="height:25px;width:200px;"></p>
                <p class="mb-0 lead user-designation gradient" style="height:15px;width:240px;margin-top:10px;"></p>
                <!-- <span class="mt-4 text-secondary gradient"></span>|<span class="mt-4 text-secondary gradient"></span> -->
                <div class="row" style="margin-top:20px;margin-left:0px;">
                  <div class="gradient" style="height:15px;width:235px;"></div>
                  <div class="gradient" style="height:15px;width:150px;margin-left:12px;"></div>
                </div>
                <div class="gradient" style="height:15px;width:150px; margin-top:20px;"></div>
              </div>
              <div class="user-details" v-if="user && !user_loading">
                <p class="mb-0 lead user-name">{{ user.name }}</p>
                <p class="mb-0 lead user-designation">{{designation}}</p>
                <p class="mt-4 text-secondary">{{ user.email}}
                  <span class="badge badge-danger" v-if="!user.email_verified" data-toggle="modal" data-target="#verifymailModal" style="cursor: pointer">Not verified</span>
                  <span class="seperator">|</span>{{ this.user.mobile }}
                  <span class="badge badge-success" v-if="user.mobile_verified">verified</span>
                  <span class="badge badge-danger" v-if="!user.mobile_verified" @click="openOtpModal()" style="cursor: pointer">Not verified</span>
                </p>
                <p class="text-secondary" v-if="user_city">{{ user_city.city_name }}</p>
              </div>
              <span class="share-button" style="position:absolute;right:15px;cursor:pointer" @click.prevent="shareProfile()" data-toggle="tooltip" data-placement="top" title="Public profile"><i class="mdi mdi-eye"></i></span>
            </div>
          </div>
          <div class="col shadow rounded-lg detail-box text-center" style="background-color:#fff" v-if="user&&user.personal_details&&user.personal_details.video_resume_duration < 10 && !user.personal_details.video_resume_verified">
            <p style="color:red;font-size:1.25rem"><strong><span class="text-capitalize">{{user.name}}</span>, your visume duration is just {{user.personal_details.video_resume_duration}} seconds</strong></p>
            <button class="btn btn-secondary" @click.prevent="openModal('visume')">Update visume</button>
            <p class="col-11 mx-auto"><strong>Recommend to update with your 2-minute visume, describing about yourself, your abilities, skills &amp; why a company should hire you.</strong></p>
          </div>
          <!-- mobile myprofile area end -->
          <div class="adVisumePopup shadow rounded-lg"  v-if="!visume && openPopUp">
            <div class="popupWrapper rounded-lg">
              <span class="red-line"></span>
              <span class="closeBtn" @click.prevent="closeVisumePopup" style="cursor:pointer;z-index:1000">x</span>
              <div class="row">
                <div class="col-md-5 text-center"><iframe ref="auto_video" width="300" height="170" src="https://www.youtube.com/embed/Sv6fKLMmCSo?autoplay=1&cc_load_policy=1&mute=1" title="YouTube video player" frameborder="0" allow="autoplay;encrypted-media;" allowfullscreen></iframe></div>
                <div class="col-md-7">
                  <h2 v-if="user">{{user.name}}, your visume is not added.</h2>
                  <p>Your face is the best interface. Video profile is a 2 minute snapshot of your profile. Hireclap profiles with video resumes are getting better attention from recruiters. Add visume to your profile now</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content shadow rounded-lg" id="v-pills-tabContent">
            <JobDetails :user="user" v-if="selectedTab=='jobDetails'"/>
            <ScheduledInterviews :user="user" v-if="selectedTab=='scheduledInterviews'"/>
            <Assessments :user="user" v-if="selectedTab=='assessments'"/>
            <Comments :user="user" v-if="selectedTab=='comments'"/>
            <PersonalDetails :user="user" v-if="selectedTab=='personalDetails'"/>
            <EducationDetails :user="user" v-if="selectedTab=='educationalDetails'"/>
            <ExperienceDetails :user="user" v-if="selectedTab=='experienceDetails'"/>
            <CallLetter :user="user" v-if="selectedTab=='selectCallLeter'"/>
            <FAQ :user="user" v-if="selectedTab=='selectFAQ'"/>
            <Institutes :user="user" v-if="selectedTab=='institutes'"/>
            <ProfileViewers :user="user" v-if="selectedTab=='profile_viewers'"/>
            <Settings :user="user" v-if="selectedTab=='selectSettings'"/>
          </div>
        </div>
        <div class="col-lg-3 mb-5 m-hide">
          <div class="content sidenav shadow rounded-lg mt-0 " style="background-color: white">
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'jobDetails'}" id="v-pills-myjobs-tab jobDetails" data-toggle="pill" @click.prevent="selectTab('jobDetails')" role="tab" aria-controls="v-pills-myjobs" aria-selected="true"><h3>My jobs</h3></div>
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'scheduledInterviews'}" id="v-pills-interviews-tab scheduledIntervies" data-toggle="pill" @click.prevent="selectTab('scheduledInterviews')" role="tab" aria-controls="v-pills-interviews" aria-selected="false">Scheduled interviews</div>
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'assessments'}" id="v-pills-interviews-tab assessments" data-toggle="pill" @click.prevent="selectTab('assessments')" role="tab" aria-controls="v-pills-assessments" aria-selected="false">Assessments</div>
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'comments'}" id="v-pills-comments-tab comments" data-toggle="pill" @click.prevent="selectTab('comments')" role="tab" aria-controls="v-pills-comments" aria-selected="false">Rating and Comments</div>
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'personalDetails'}" id="v-pills-personal-tab personalDetails" data-toggle="pill" @click.prevent="selectTab('personalDetails')" role="tab" aria-controls="v-pills-personal" aria-selected="false">Personal details</div>
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'educationalDetails'}" id="v-pills-education-tab educationalDetails" data-toggle="pill" @click.prevent="selectTab('educationalDetails')" role="tab" aria-controls="v-pills-education" aria-selected="false">Education</div>
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'experienceDetails'}" id="v-pills-workexperience-tab experienceDetails" data-toggle="pill" @click.prevent="selectTab('experienceDetails')" role="tab" aria-controls="v-pills-workexperience" aria-selected="false">Work Experience</div>
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'institutes'}" id="v-pills-faq-institutes institutes" data-toggle="pill" @click.prevent="selectTab('institutes')" role="tab" aria-controls="v-pills-institutes" aria-selected="false">Institutes</div>
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'profile_viewers'}" id="v-pills-faq-profile_viewers profile_viewers" data-toggle="pill" @click.prevent="selectTab('profile_viewers')" role="tab" aria-controls="v-pills-profile_viewers" aria-selected="false">Profile viewers</div>
              <div class="p-3 nav-link" :class="{'active': selectedTab == 'selectSettings'}" id="v-pills-faq-tab selectSettings" data-toggle="pill" @click.prevent="selectTab('selectSettings')" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</div>
            </div>
          </div>
          <div class="mt-5 p-3" style="background-color:white;" v-if="!visume||!resume_path">
            <h6 class="text-danger"><strong>Profile incomplete!</strong></h6>
            <span style="font-size:12px;color: #0054ad;cursor: pointer;" v-if="!visume" v-on:click="upload('visume')">Visume </span><b-badge pill variant="danger" v-if="!visume">Not uploaded</b-badge><br>
            <span style="font-size:12px;color: #0054ad;cursor: pointer;" v-if="!resume_path" v-on:click="upload('resume')">Resume </span><b-badge pill variant="danger" v-if="!resume_path">Not uploaded</b-badge>
          </div>
        </div>
      </div>
      <VisumeModal :visume="user.personal_details.video_resume_path" :open="visumeModal.open" v-if="user.personal_details"/>
      <b-modal centered id="profile_alert" ref="profile-alert" title="Complete your profile" hide-footer>
        <div class="container">
          <div class="col-lg-12 bg-white" style="padding-y: 0.5rem ">
            <!-- <h5><strong>Complete your profile</strong></h5> -->
            <p v-if="!visume"><b-button size="sm" variant="primary" v-on:click="upload('visume')">Upload</b-button> your video profile(Visume)</p>
            <p v-if="!resume_path"><b-button size="sm" variant="primary" v-on:click="upload('resume')">Upload</b-button> your resume</p>
            <p v-if="!user.mobile_verified" style="margin-bottom:3rem;"><b-button size="sm" variant="primary" v-on:click="openOtpModal()">Verify</b-button> your mobile</p>
            <span style="font-size:10.5px;color: #808080;"><i>Upload your profile section to increases your chances of getting hired Profiles with 80% or more completion have a higher percentage of shortlisting based on our hiring trends.</i></span>
          </div>
        </div>
        <template>
          <div class="float-right">
            <b-button size="sm" variant="warning" v-on:click="closeModal('profile-alert')">Skip</b-button>
          </div>
        </template>
      </b-modal>
      <b-modal ref="upload-resume" title="Upload Resume" hide-footer>
        <div class="container">
          <v-form ref="form" lazy-validation v-model="valid">
            <v-file-input accept=".doc,.docx,.rtf,.pdf"  v-model="resume" placeholder="Post resume" append-icon="mdi-file" prepend-icon="" :rules="[v => !!v || 'Upload valid resume']"  show-size outlined></v-file-input>
          </v-form>
        </div>
        <template>
          <div class="float-right">
            <b-button class="mr-2" size="sm" v-on:click.prevent="closeModal('upload-resume')">Cancel</b-button>
            <b-button size="sm" variant="success" v-on:click.prevent="uploadResume()" v-if="!loading">Save</b-button>
            <b-button size="sm" variant="success" v-if="loading">...</b-button>
          </div>
        </template>
      </b-modal>
      <button class="float m-show" @click="mbar_open">Menu</button>
    </div>
    <b-modal id="send-otp-modal" hide-footer>
      <template #modal-title> Verify Mobile </template>
      <b-form ref="login-form">
        <div class="form-group">
          <p>Secure your account with a second way to prove who you are</p>
        </div>
        <div class="form-label-group" v-if="!mobileFormHidden">
          <div class="align-items-center d-flex">
            <input id="mobile" v-model="form.mobile" ref="mobile" :disabled="disableEdit" type="text" placeholder="Enter your mobile" :class="{'is-invalid':typeof validate.response.tel != 'undefined' &&!validate.response.tel.val,}" class="form-control" @input="validate.mobileValidation(form.mobile, 'tel')"/>
            <span class="ml-2"><v-icon @click="disableEdit = !disableEdit" class="mdi mr-1 mb-2" size="1rem" color="#6c757d">{{ $icons.pencilCircleOutline }}</v-icon></span>
          </div>
          <div class="invalid-feedback" style="display: block" v-if=" typeof validate.response.tel != 'undefined' && !validate.response.tel.val">{{ validate.response.tel.msg }}</div>
          <div class="invalid-feedback" style="display:block;" v-if="otpError !== '' && otpError !== 'limit_exceeded'">{{ otpError }}</div> 
          <div class="invalid-feedback" style="display:block;" v-if="otpError == 'limit_exceeded'">Too many attempts write to <a href="mailto:recruit@hireclap.com" target="_blank">recruit@hireclap.com</a> to verify your mobile</div>
        </div>
        <div class="align-items-center col-9 d-flex form-label-group justify-content-around ml-auto mx-auto" ref="otp-input" v-if="mobileFormHidden">
          <input id="otp_1" v-on:keyup="$event.target.nextElementSibling.focus()" v-on:keyup.delete="$event.target.focus()" v-model="form.otp.otp_1" ref="otp_1" type="text" class="form-control col-2 text-center" maxlength="1"/>
          <input id="otp_2" v-on:keyup="$event.target.nextElementSibling.focus()" v-on:keyup.delete="$event.target.previousElementSibling.focus()" v-model="form.otp.otp_2" ref="otp_2" type="text" class="form-control col-2 text-center" maxlength="1"/>
          <input id="otp_3" v-on:keyup="$event.target.nextElementSibling.focus()" v-on:keyup.delete="$event.target.previousElementSibling.focus()" v-model="form.otp.otp_3" ref="otp_3" type="text" class="form-control col-2 text-center" maxlength="1"/>
          <input id="otp_4" v-on:keyup.delete="$event.target.previousElementSibling.focus()" v-model="form.otp.otp_4" ref="otp_4" type="text" class="form-control col-2 text-center" maxlength="1"/>
        </div>
        <div class="invalid-feedback col-9" style="display: block" v-if="otpError !== '' && mobileFormHidden"> {{ otpError }}</div>
        <div class="form-group text-center m-3" v-if="!mobileFormHidden">
          <b-button type="submit" class="float-right" variant="primary" @click="sendOtp($event)">Send Otp</b-button>
        </div>
        <div class="form-group text-center m-3" v-if="mobileFormHidden">
          <b-button @click="verifyOtp" class="float-right" variant="primary">Verify Otp</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import '../assets/hire.css';
import AwsUpload from "../../utils/s3upload"
import validation from '../libraries/validFormat' ;

import Actions from '../libraries/apiActions.js';
import { mapState, mapGetters } from 'vuex';
import store from '../store';
import { COURSES } from '../store/modules/courses/constants';
import { STATES } from '../store/modules/states/constants';
import { JOB_ROLE } from '../store/modules/jobrole/constants';
import { DESIGNATIONS } from '../store/modules/designations/constants';
// import { ContentLoader } from 'vue-content-loader';

const VisumeModal = () => import(/* webpackPrefetch: true */ "@/components/VisumeModal");
const JobDetails = () => import(/* webpackPrefetch: true */ "@/components/JobDetails");
const PersonalDetails = () => import(/* webpackPrefetch: true */ "@/components/PersonalDetails");
const EducationDetails = () => import(/* webpackPrefetch: true */ "@/components/EducationDetails");
const ExperienceDetails = () => import(/* webpackPrefetch: true */ "@/components/ExperienceDetails");
const CallLetter = () => import(/* webpackPrefetch: true */ "@/components/CallLetter");
const Faq = () => import(/* webpackPrefetch: true */ "@/components/FAQ");
const Settings = () => import(/* webpackPrefetch: true */ "@/components/Settings");
const ScheduledInterviews = () => import(/* webpackPrefetch: true */ "@/components/ScheduledInterviews");
const Assessments = () => import(/* webpackPrefetch: true */ "@/components/Assessments");
const Comments = () => import(/* webpackPrefetch: true */ "@/components/Comments");
const Institutes = () => import(/* webpackPrefetch: true */ "@/components/Institutes");
const ProfileViewers = () => import(/* webpackPrefetch: true */ "@/components/ProfileViewers");

var breadcrumbJson = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": window.location.origin
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Profile",
        "item": window.location.href
      }]
    }
export default {
  name: "dashboard",
  components:{
    'JobDetails': JobDetails,
    'PersonalDetails': PersonalDetails,
    'EducationDetails': EducationDetails,
    'ExperienceDetails': ExperienceDetails,
    'CallLetter': CallLetter,
    'ScheduledInterviews':ScheduledInterviews,
    'Assessments': Assessments,
    'FAQ': Faq,
    'Settings': Settings,
     VisumeModal,
     Comments,
     Institutes,
     ProfileViewers
    //  ContentLoader
  },
  data() {
    return {
      validate: new validation(),
      selectedTab:'jobDetails',
      visumeModal:{
        open:'exit',
      },
      token: null,
      user: {},
      user_city: {},
      user_state: null,
      user_career: null,
      visume:'',
      visume_path:'',
      selectJob:true,
      selectPersonal:false,
      selectEducation:false, 
      selectExperience:false,
      selectCallLeter:false,
      selectFAQ:false,
      selectSettings:false,
      profileImage:null,
      selectedFile:null,
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      designation:null,
      openPopUp:false,
      action:new Actions(),
      resume_path:null,
      resume: null,
      update:{
        resume:null
      },
      valid:true,
      loading:false,
      user_loading:true,
      form: {
        mobile: 0,
        otp: {
          otp_1: "",
          otp_2: "",
          otp_3: "",
          otp_4: "",
        },
      },
      disableEdit: true,
      mobileFormHidden: false,
      validateError: false,
      otpError: "",
    }
  },
  async beforeMount() {
    window.scrollTo(0, 0);
    if(this.$cookies.get("hire-seeker")) this.token = this.$cookies.get("hire-seeker").token;
    if(!this.token){
      let redirect_from = '/myprofile';
      if(this.$route.query.tab) redirect_from += '?tab='+ this.$route.query.tab;
        this.$router.push({
        name: 'JobseekerSign',
        query: { redirect_from }
        });
    } else {
        await this.getUser();
        if(this.user){
          this.form.mobile = this.user.mobile;
        }
        if(this.user&&this.$cookies.get('hire-seeker').alert =='yes'&&(!this.resume_path || !this.visume_path || !this.user.mobile_verified)){
          console.log(this.user);
          this.$refs['profile-alert'].show();
        }
        if(this.$route.query.tab) {
          this.selectTab(this.$route.query.tab);
        } else this.selectTab('jobDetails')
    }
    
   
  },
  metaInfo: (vue) => {
    const user = vue.user;
    const user_city = vue.user_city;
    return {
      script: [
        {type: 'application/ld+json', json: breadcrumbJson},
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org/",
            "@type": "PersonSchema",
            name: `${user ? user.name : ""}`,
            email: `${user ? user.email: ""}`,
            mobile: `${user ? user.mobile : ""}`,
            gender: `${user&&user.personal_details ? (user.personal_details.gender==1?'Male':(user.personal_details.gender==2?'Female':'Other')) : ""}`,
            course: `${user&&user.high_qualification&&user.high_qualification.course ? user.high_qualification.course.course_name : ""}`,
            branch: `${user&&user.high_qualification&&user.high_qualification.branch ? user.high_qualification.branch.branch_name : ""}`,
            userLocation: user_city.city_name,
            userLocationRequirements: {
              "@type": "Country",
              name: 'India',
            },
          },
        }
      ],
    };
  },
  beforeCreate() {
      this.$store = store;
  },
  created() {
    const apiFn = this.action;
    this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
    this.cachedDispatch(DESIGNATIONS.GET_DESIGNATIONS, { param: apiFn });
    setTimeout(() => this.openPopUp = true, 2000);
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        courses: state => state.courses.courses.courses,
        locations: state => state.states.locations.locations,
        jobrole: state => state.jobrole.jobrole.jobrole,
        designations : state => state.designations.designations.designations
    }),
  },
  methods: {
    openModal : function(type= ''){
      this.open = type == "" ? this.open : type;
      if (this.open == "profile-pic") {
        this.$refs["profile-pic"].show();
      }
        this.visumeModal.open = 'exit';
        this.visumeModal.open = type;
    },
    closeModal(data){
      this.$refs[data].hide();
      if(data == 'profile-alert'){
        let cookie = this.$cookies.get('hire-seeker');
        let updated_cookie = {token:cookie.token,name:cookie.name,visume_path:cookie.visume_path,resume_path:cookie.resume_path,alert:'no'};
        this.$cookies.remove("hire-seeker");
        this.$cookies.set("hire-seeker",updated_cookie);
      }
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    openOtpModal() {
       this.$refs['profile-alert'].hide();
      this.mobileFormHidden = false;
      this.validateError = false;
      this.otpError = "";
      this.$bvModal.show("send-otp-modal");
    },
    sendOtp(e) {
      e.preventDefault();
      if (
        this.validate.mobileValidation(this.form.mobile, "tel")?.val === false
      ) {
        this.validateError = true;
      }
      if (!this.validateError) {
        let token = this.$cookies.get("hire-seeker").token;
        let data = {
          input: {
            mobile: this.form.mobile,
          },
          token: token,
        };
        this.action.sendOtp(data).then((res) => {
          if (res.status) {
            this.mobileFormHidden = true;
            this.otpError = "";
          } else {
            this.otpError = res.message;
          }
        });
      }
    },
    verifyOtp() {
      let token = this.$cookies.get("hire-seeker").token;
      let otp =
        this.form.otp.otp_1 +
        this.form.otp.otp_2 +
        this.form.otp.otp_3 +
        this.form.otp.otp_4;
      let data = {
        input: {
          mobile: this.form.mobile,
          otp: otp,
        },
        token: token,
      };
      this.action.verifyOtp(data).then((res) => {
        if (res.status) {
          this.getUser();
          this.$bvModal.hide("send-otp-modal");
          this.showMsgModal('You have successfully verified your mobile number');
          this.mobileFormHidden = true;
        } else {
          this.otpError = res.message;
        }
      });
      console.log(otp);
    },
    showMsgModal(msg = null) {
      this.boxOne = '';
      this.$bvModal.msgBoxOk(msg, {
          buttonSize: 'sm',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
      });
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    async getUser() {
      await this.action.getUser(this.$cookies.get("hire-seeker").token ).then(async (data) => {
        if (data) {
          this.user_loading = false;
          this.user = data.user;
          this.user_details = this.user?.personal_details
          this.user_city = data.user?.personal_details?.user_city;
          this.user_state = this.user_city?.state?.state_name;
          this.user_career = this.user?.careerPreferencesDetail;
          this.visume = data.user?.personal_details?.video_resume_path;
          this.visume_path = data.user?.personal_details?.video_resume_path + '#t=0.1';
          this.resume_path = data.user?.personal_details?.resume_path;
          await this.$store.state.jobrole.jobrole.jobrole.forEach((role) => {
            if(role.value == this.user_career.fst_prefered_role){
              this.designation = role.text;
            }
          })
        } else { 
          // this.$router.replace("/").catch(() => {});
        }
      }).catch((err) => {
        console.log(err);
        if (err) {
          // this.$router.replace("/").catch(() => {});
        }
      });
    },
    changeActive(e){
      e.preventDefault()
      var elems = document.querySelectorAll(".active");
        [].forEach.call(elems, function(el) {
          el.classList.remove("active");
        });
        e.target.classList.add('active')
    },
    shareProfile(){
      let url = this.user.name.split(' ').join('-')+this.user.id;
      window.open('/candidate/'+url, "_blank").focus();
    },
    selectTab(tab){
      if(tab){
        this.mbar_close();
        this.selectedTab = tab;
      }
    },
    filesChange(event) {
      this.selectedFile = event.target.files[0];
    },
    closeVisumePopup(){
      this.openPopUp = false;
    },
    mbar_open() {
    document.getElementById("mySidebar").style.left = (document.getElementById("mySidebar").style.left == "0px")?"-255px":"0px";
    },

    mbar_close() {
    document.getElementById("mySidebar").style.left ="-255px"
    },

    upload(data){
      this.$refs['profile-alert'].hide();
      if(data == 'visume'){
        this.openModal('visume');
      }else if(data == 'resume'){
        this.$refs['upload-resume'].show();
      }
    },
    async uploadResume(){
      let token = this.$cookies.get("hire-seeker").token;
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        this.loading = true;
        const file = this.resume;
        const awsUploader = new AwsUpload();
        try {
          var extension = this.resume.name.split('.')[this.resume.name.split('.').length-1]
          var d = new Date();
          var month = d.getMonth() + 1;
          var day = d.getDate();
          var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
          var timestamp = Math.floor(Date.now() / 1000);
          var filename = 'resume/'+ current_date+"/"+this.user.name + timestamp + "."+extension;
          const response = await awsUploader.uploadToS3(file, filename);
          this.update.resume = response.url;
          this.action.updateResume(this.update.resume,token).then(data =>{
            if(data.message == "Resume Updated"){
              this.loading = false;
              this.resume = false;
              this.$refs['upload-resume'].hide();
              let cookie = this.$cookies.get('hire-seeker');
              let updated_cookie = {token:cookie.token,name:cookie.name,visume_path:cookie.visume_path,resume:'yes',resume_path:this.update.resume};
              this.$cookies.remove("hire-seeker");
              this.$cookies.set("hire-seeker",updated_cookie);
              location.reload();
            }else{
              this.loading = false;
            }
          }).catch(err =>{
            this.loading = false;
            console.log(err);
          })
        } catch (error) {
          console.error('Failed to upload file to S3:', error);
        }
      }
    }

  },
};
</script>