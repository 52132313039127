
import { COURSES_MUTATIONS } from './constants';

export async function getCourses({ commit },
    { param } = {}) {
    try {
        let coursesList = [];
        commit(COURSES_MUTATIONS.COURSES_PROGRESS);
        await param.getCourse().then(async (data) => {
            coursesList.push({header: 'Popular Courses'})
            await data.courses.forEach((element) => {  
                if((element.popular_rating == 1&&element.course_id<5000 && element.course_id != 37)||(element.popular_rating == 1 &&element.course_id == 5013)){
                coursesList.push({text:element.course_name,id:element.course_id,group:'Popular Courses'})
                }
            });
            coursesList.push({divider:true})
            coursesList.push({header: 'Graduation Courses'});   
            await data.courses.forEach((element) => {  
                if((element.popular_rating == 0 && element.course_type == 'graduation'&&element.course_id!=5001 && element.course_id != 37) || (element.popular_rating == 0 && element.course_type == 'graduation'&&element.course_id == 5013)){
                coursesList.push({text:element.course_name,id:element.course_id,group:'Graduation Courses'})
                }
            });
            coursesList.push({divider:true})
            coursesList.push({header: 'PG Courses'});   
            await data.courses.forEach((element) => {  
                if((element.popular_rating == 0 && element.course_type == 'pg'&&element.course_id!=5000 && element.course_id != 37) || (element.popular_rating == 0 && element.course_type == 'pg'&&element.course_id == 5013)){
                coursesList.push({text:element.course_name,id:element.course_id,group:'PG Courses'})
                }
            });
            coursesList.push({divider:true})
            coursesList.push({header: 'Other Category'});   
            await data.courses.forEach((element) => { 
                if((element.popular_rating == 0 && element.course_type == 'others_category'&&element.course_id!=5006 && element.course_id != 37) || (element.popular_rating == 0 && element.course_type == 'others_category'&&element.course_id == 5013)){
                coursesList.push({text:element.course_name,id:element.course_id,group:'Other Category'})
                }
            });
        });
        commit(COURSES_MUTATIONS.COURSES_SUCCESS, coursesList);
    } catch(error) {
        commit(COURSES_MUTATIONS.COURSES_FAILURE, error);
    }
}