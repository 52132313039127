/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex';
import cache from './modules/cache/index';
import { CACHE_ACTIONS } from './modules/cache/constants';
import vuexCache from '../libraries/vuex-cache';
import courses from './modules/courses';
import jobrole from './modules/jobrole';
import states from './modules/states';
import updates from './modules/misc_updates';
import designations from './modules/designations';
import branches from './modules/branches';


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
    modules: {
        cache,
        courses,
        jobrole,
        states,
        designations,
        updates,
        branches
    },
    strict: debug,
    plugins: [vuexCache]
});

store.dispatch(CACHE_ACTIONS.PERSIST_STORE_CACHE, store.cache);

export default store;
