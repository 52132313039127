
import URIS from "./consUri";
import apiCom from './apiCom';

// const token= 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9zdGF0aWNfYXV0aCIsImlhdCI6MTYxODI5NTAwMiwiZXhwIjo5ODg3MTUxNTEyMiwibmJmIjoxNjE4Mjk1MDAyLCJqdGkiOiJ0bXRzdm9EODZkY2dSMFFWIiwic3ViIjozMCwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9._siK35g-RNOcY9Jh21RcmEF6LQC6fz_BP8hq6pZ9c8U'
const token = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9zdGF0aWNfYXV0aCIsImlhdCI6MTYxOTQ2NDQwOCwiZXhwIjo5ODk0Mjg0ODU4OSwibmJmIjoxNjE5NDY0NDA5LCJqdGkiOiJ2amNRbjloTkJkRk5WR3pRIiwic3ViIjo2MywicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.k6ja2Bj2IgsdOu9WWcqM2mPWc1Zt6YQwEEhEEyEy4gA'
const seeker_token = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9zdGF0aWNfYXV0aCIsImlhdCI6MTYyMDYzMTA3NSwiZXhwIjo5OTAxOTE5OTE1NSwibmJmIjoxNjIwNjMxMDc1LCJqdGkiOiI1WVQwTXVoM0Zid0ZoY0RlIiwic3ViIjo0MDA4LCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.lBXcvw3IPM7XwXIrateRV4gIkjypGurV3h4UUkBUNgk'
const employer_token = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9zdGF0aWNfYXV0aCIsImlhdCI6MTYyMDY1ODA4OCwiZXhwIjo5OTAyMDg0NzA2OCwibmJmIjoxNjIwNjU4MDg4LCJqdGkiOiJTU3NBcjlGVmxTSVgg4ZXhDIiwic3ViIjo0MDA4LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.WlEq0b4Wzpu1XeHAy1LHCApinjfh2Aq7kSfUlNnw6MseyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9zdGF0aWNfYXV0aCIsImlhdCI6MTYyMDY1ODA4OCwiZXhwIjo5OTAyMDg0NzA2OCwibmJmIjoxNjIwNjU4MDg4LCJqdGkiOiJTU3NBcjlGVmxTSVg4ZXhDIiwic3ViIjo0MDA4LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.WlEq0b4Wzpu1XeHAy1LHCApinjfh2Aq7kSfUlNnw6Ms'
class Actions{

    constructor() {
        URIS
    }


    /************** Static Api Section ******************/

    /**
     * @param {object} data 
     */

  async getStates(){
      let res = new apiCom('static', URIS.states).get({Authorization:token});
      return res;
  }
  async getSubcity(data){
      let res = new apiCom('static', URIS.subcity+data).get({Authorization:token});
      return res;
  }
  async getCourse(){
      let res = new apiCom('static', URIS.courses).get({Authorization:token});
      return res;
  }
  async getSpec(data = ''){
    let param = ''; if(data !== '') param = '/' + data;
      let res = new apiCom('static', URIS.specifications+param).get({Authorization:token});
      return res;
  }
  async getDesignations(){
      let res = new apiCom('static', URIS.designations).get({Authorization:token});
      return res;
  }
  async getJobrole(){
      let res = new apiCom('static', URIS.jobrole).get({Authorization:token});
      return res;
  }
  async getCities(){
      let res = new apiCom('static', URIS.cities).get({Authorization:token});
      return res;
  }
  async getQualification(){
      let res = new apiCom('static', URIS.high_qualification).post({Authorization:token});
      return res;
  }
  async getLanguages(){
      let res = new apiCom('static', URIS.languages).get({Authorization:token});
      return res;
  }
  async getSkills(){
      let res = new apiCom('static', URIS.skills).get({Authorization:token});
      return res;
  }    
  async getJobDesignations(){
      let res = new apiCom('static' , URIS.job_designations).get({Authorization:token});
      return res;
  }
  async getJobCategories(){
      let res = new apiCom('static' , URIS.jobcategories).get({Authorization:token});
      return res
  }
  async getUniversities(state_id = null){
      let param = '';
      if(state_id) param += '/'+ state_id;
      let res = new apiCom('static', URIS.university+param).get({Authorization:token});
      return res;
  }
  async notFound(){
    let res = new apiCom('static', '/404').get({Authorization:token});
    return res;
  }
    /************** Jobseeker Section ******************/

    /**
     * Jobseeker Section
     * @param {object} data 
     */
  async seekerLogin(data){
      let res = new apiCom('seeker' , URIS.login).post(data,{Authorization:seeker_token});
      return res;
  }
  async seekerRegister(data){
      let res = new apiCom('seeker', URIS.register).post(data,{Authorization:seeker_token});
      return res;
  }
  async verifyEmail(data){
    let res = new apiCom('seeker', URIS.verify_email).post({}, { Authorization: 'Bearer ' +data.token});
    return res;
  }
  async resetPassword(data) {
    let res = new apiCom('seeker', URIS.reset_password).post(data.input,{Authorization:'Bearer '+data.token});
    return res;
  }
  async sendVerification(data) {
    let res = new apiCom('seeker', URIS.send_verification).post(data,{Authorization:seeker_token});
    return res;
  }
  async sendVerificationEmp(data) {
    let res = new apiCom('employer', URIS.send_verification).post(data,{Authorization:employer_token});
    return res;
  }
  async sendResetLink(data) {
    let res = new apiCom('seeker', URIS.send_reset_link).post(data,{Authorization:seeker_token});
    return res;
  }
  async forgotPasswordReset(data) {
    let res = new apiCom('seeker', URIS.forgot_password_reset).post(data.input,{Authorization:'Bearer ' + data.token});
    return res;
  }
  async sendOtp(data) {
    let res = new apiCom('seeker', URIS.sendOtp).post(data.input,{Authorization:'Bearer '+data.token});
    return res;
  }
  async verifyOtp(data) {
    let res = new apiCom('seeker', URIS.verifyOtp).post(data.input,{Authorization:'Bearer '+data.token});
    return res;
  }
  async seekerLogout(data) {
    let res = new apiCom('seeker', URIS.seeker_logout).get({Authorization:'Bearer '+data});
    return res;
  }
  async getUser(data){
      let res = new apiCom('seeker', URIS.employee).post({},{Authorization:'Bearer '+data});
      return res;
  }
  async uploadVisume(data){
    let res = new apiCom('seeker', URIS.video_profile).post({resume: data.resume, duration: data.duration},{Authorization: 'Bearer '+data.token});
    return res;
  }
  async uploadVisumeID(data){
    let res = new apiCom('seeker', URIS.video_profile+'/'+data.seeker_id).post({resume: data.resume, duration: data.duration,id:data.seeker_id});
    return res;
  }
  async updateProfilePic(data, token){
    let res = new apiCom('seeker', URIS.profile_image).post({'profile_pic':data},{Authorization:'Bearer '+token});
    return res;
  }
  async updateSeekerProfile(data,auth_token){
      let res = new apiCom('seeker', URIS.personaldetails).post(data,{Authorization:'Bearer '+auth_token});
      return res;
  }
  async updateAlertSettings(status, token){
    let res = new apiCom('seeker', URIS.update_alerts+ '/'+status).post({},{Authorization:'Bearer '+token});
    return res;
}
  async updateResume(data, auth_token){
    let res = new apiCom('seeker', URIS.editResume).post({resume:data},{Authorization:'Bearer '+auth_token});
    return res;
  }
  async updateXthDetails(data, auth_token){
    let res = new apiCom('seeker', URIS.editXth).post(data,{Authorization:'Bearer '+auth_token});
    return res;
  }
  async updateXiithDetails(data, auth_token){
    let res = new apiCom('seeker', URIS.editXiith).post(data,{Authorization:'Bearer '+auth_token});
    return res;
  }
  async updateHighestQualification(data, auth_token){
    let res = new apiCom('seeker', URIS.updatehq).post(data,{Authorization:'Bearer '+auth_token});
    return res;
  }
  async updateMoreQualification(data, auth_token){
    let res = new apiCom('seeker', URIS.morequalifiation).post(data,{Authorization:'Bearer '+auth_token});
    return res;
  }
  async editExperience(data, auth_token){
    let res = new apiCom('seeker',URIS.updateExp).post({experience:data},{Authorization:'Bearer '+auth_token});
    return res;
  }
  async addExperience(data, auth_token){
    let res = new apiCom('seeker', URIS.experience).post(data,{Authorization:'Bearer '+auth_token});
    return res;
  }
  async removeCompany(id,token){
    let res = new apiCom('seeker', URIS.remove_company+'/'+id).post('',{Authorization:'Bearer '+token});
    return res;
  }
  async removeQualification(id,token){
    let res = new apiCom('seeker', URIS.remove_qualification+'/'+id).post('',{Authorization:'Bearer '+token});
    return res;
  }
  async updateCareerPreference(data, auth_token){
    let res = new apiCom('seeker', URIS.careerpreference).post(data,{Authorization:'Bearer '+auth_token});
    return res;
  }
  async getJobsByURL(url){
    let data = url? '/'+url:'';
    let res = new apiCom('seeker', URIS.jobsByUrl+data).get({Authorization:seeker_token});
    return res;
  }
  async scheduledInterviews(token) {
    let res = new apiCom('seeker' , URIS.interview).post({},{Authorization:'Bearer ' + token});
    return res  
  }
  async getProfileViews(token) {
    let res = new apiCom('seeker' , URIS.profile_views).get({Authorization:'Bearer ' + token});
    return res  
  }
  async getComments(token) {
    let res = new apiCom('seeker' , URIS.comments).get({Authorization:'Bearer ' + token});
    return res  
  }
  async getCourseCategories(){
    let res = new apiCom('static', URIS.course_categories).get({Authorization:token});
    return res;
}
  async getInstitutes(data){
    console.log(data);
    let res = new apiCom('static' , URIS.institutes).post(data,{Authorization:token});
    return res;
}
async getTrainingInstitutes(data){
  let res = new apiCom('static' , URIS.training_institutes).post(data,{Authorization:token});
  return res;
}
  async getInstituteDetails(id){
    let res = new apiCom('seeker' , '/institute/'+id).get({Authorization: token});
    return res;
  }
  async getCollegeDetails(id){
    let res = new apiCom('seeker' , '/college/'+id).get({Authorization: token});
    return res;
  }
  async getInstituteCourses(id){
    let res = new apiCom('seeker' , '/institute/course/'+id).get({Authorization:'Bearer ' + token});
    return res;
  }
  async getInstituteJobs(param,token){
    let res = new apiCom('seeker' , '/institute/jobs'+param).get({Authorization:'Bearer ' + token});
    return res;
  }
  async getInstitutePrivateJobs(param,token){
    let res = new apiCom('seeker' , '/institute/private/jobs'+param).get({Authorization:'Bearer ' + token});
    return res;
  }
  async getCollegePrivateJobs(param,token){
    let res = new apiCom('seeker' , '/college/private/jobs'+param).get({Authorization:'Bearer ' + token});
    return res;
  }
  async getRegisteredInstitutes(data){
    let res = new apiCom('seeker' , '/institutes/registered').post(data);
    return res;
  }
  async getRegisteredColleges(data){
    let res = new apiCom('seeker' , '/colleges/registered').post(data);
    return res;
  }
  async submitLead(data, token){
    let res = new apiCom('seeker' , '/institute/lead').post(data, {Authorization:'Bearer ' + token});
    return res;
  }
  async submitCollegeLead(data, token){
    let res = new apiCom('seeker' , '/colleges/lead').post(data, {Authorization:'Bearer ' + token});
    return res;
  }
  async getRegisteredCourses(data){
    let res = new apiCom('seeker' , '/courses/registered').post(data);
    return res;
  }
  async deleteInstituteCourse(id,token){
    let res = new apiCom('seeker' , URIS.training_details+'/'+id).delete({Authorization:'Bearer ' + token});
    return res;
  }
  async addUserTrainingDetails(data,token){
    let res = new apiCom('seeker' , URIS.training_details).post(data,{Authorization:'Bearer ' + token});
    return res;
  }
  async tagUserCollege(data, token){
    let res = new apiCom('seeker' , URIS.tag_college).post(data,{Authorization:'Bearer ' + token});
    return res;
  }
  async getUserTrainingDetails(token){
    let res = new apiCom('seeker' , URIS.training_details).get({Authorization:'Bearer ' + token});
    return res;
  }
  async getUserCourseInterest(id, token){
    let res = new apiCom('seeker' , '/courses/'+id+'/interest').get({Authorization:'Bearer ' + token});
    return res;
  }
  async getJobList(data){
    // var param = data.jobrole ? ((data.location)?'?job_role='+data.jobrole+'&': '?job_role='+data.jobrole):'';
    // param += data.location? ((data.jobrole)?'city_id='+data.location:'?city_id='+data.location):'';
    // // param += data.course? ((data.course)?'course='+data.course:'?course='+data.course):'';
    var param = [];
    for (var key in data) {
      if (data[key] != null && data[key] != '') {
        param.push(encodeURI(key + '=' + data[key]));
      }
    }
    param = param.join('&');
    param = (param)?'?'+param:'';
    let res = new apiCom('seeker', URIS.listing+param).get({Authorization:seeker_token});
    return res;
  }
  async listJobsByUrl(url){
    let res = new apiCom('seeker', url).get({Authorization:seeker_token});
    return res;
  }
  async getUrls(type) {
    let res = new apiCom('seeker', URIS.listUrl+'?type='+type).get();
    return res;
  }
  async getRecJobList(data) {
    var param = [];
    for (var key in data) {
      if (data[key] != null && data[key] != '') {
        param.push(encodeURI(key + '=' + data[key]));
      }
    }
    param = param.join('&');
    param = (param)?'?'+param:'';
    let res = new apiCom('seeker', URIS.recListing+param).get({Authorization:seeker_token});
    return res;
  }

  async getJobDetailsId(data){
    let res = new apiCom('seeker', URIS.jobDetails+data.id).get({Authorization:seeker_token});
    return res;
  }

  async applyJob(data){
    var job = {data:[data]}
    let res = new apiCom('employer', URIS.jobapplications).post(job,{Authorization:employer_token});
    return res;
  }

  async getAppliedJobList(id,token){
    let res = new apiCom('seeker', URIS.applied_list+id).get({Authorization:'Bearer '+token});
    return res;
  }
    /************** Employer Section ******************/

  async employerLogin(data){
      let res = new apiCom('employer' , URIS.employer_login).post(data,{Authorization: employer_token});
      return res;
  }
  async employerRegister(data){
      let res = new apiCom('employer' , URIS.employer_register).post(data,{Authorization: employer_token});
      return res;
  }
  async getEmployer(data){
      let res = new apiCom('employer' , URIS.employer).get({Authorization: 'Bearer '+data});
      return res
  }
  async viewContact(data, token){
    let res = new apiCom('employer' , URIS.view_contact).post(data,{Authorization: 'Bearer '+token});
    return res
  }
  async jobPost(data){
    var job = {toPost:data}
    let res = new apiCom('temp' , URIS.post_job).post(job);
    return res;
  }
  async getJobs(data){
      var param = '?cli='+data.user_id+'&p='+data.pageNumber;

      if(data.designation) param += '&designation='+data.designation;
      if(data.city) param += '&city='+data.city;
      if(data.posted_from) param += '&posted_from='+data.posted_from;
      if(data.posted_to) param += '&posted_to='+data.posted_to;

      let res = new apiCom('temp', URIS.list_jobs+param).get();
      return res;
  }
  async getJobDetails(data){
    let res = new apiCom('temp', URIS.getJob+'/'+data.id+'/'+data.type+'?cli='+data.user_id).get();
    return res;
  }

  async sendContact(data){
    let res = new apiCom('seeker', URIS.contact).post(data);
    return res;
  }
  //admin response api
  async getResponses(id){
    let res = new apiCom('admin_temp', '/job/'+id+'/responses?all').get();
    return res;
  }
  async activeJobfairExists(){
    let res = new apiCom('admin' , URIS.active_jobfairs).get();
    return res;
  }   

  async getQuestionnaire(id,token){
    let res = new apiCom('seeker', URIS.questionnaire+'/'+id).get({Authorization: 'Bearer '+token});
    return res;
  }

  async existQuestionnaire(id,uid){
    let res = new apiCom('seeker', '/hasQuestionnaire/'+id+'?uid='+uid).get({Authorization:seeker_token});
    return res;
  }
  async saveQuestionnaireAnswers(data,token){
    let res = new apiCom('seeker', URIS.submit_questionnaire).post({results:data},{Authorization: 'Bearer '+token});
    return res;
  }     
  async getCandidateProfile(id){
    let res = new apiCom('seeker', URIS.candidate_profile+'/'+id).get();
    return res;
  }
  async getUpdates(update_type){
    let res = new apiCom('seeker', URIS.updates+'?update_type='+update_type).get();
    return res;
  }
  async getAssessments(token){
    let res = new apiCom('seeker', URIS.assessments).get({Authorization:'Bearer '+token});
    return res;
  }
  async getTestDetails(tracker_id, token){
    let res = new apiCom('seeker', URIS.assessments+'/'+tracker_id).get({Authorization:'Bearer '+token});
    return res;
  }
  async getFormDetails(job_id){
    let res = new apiCom('seeker', URIS.formDetails+'/'+job_id).get({Authorization:seeker_token});
    return res;
  }

  async submitPrivateJobForm(form,questionnare,job_id){
    let req = {form:form,questions:questionnare};
    console.log(req);
    let res = new apiCom('seeker', URIS.formDetails+'/'+job_id).post(req,{Authorization:seeker_token});
    return res;
  }
  async getPost(id){
    let res = new apiCom('seeker' , URIS.post+'/'+id).get({Authorization:seeker_token});
    return res;
  }
  async getActiveRedirects(link = null){
    let param = ''; 
    if(link) param = '/active?link='+link
    let res = new apiCom('admin', URIS.redirects+param).get();
    return res;
  }
}

export default Actions
