export const STATES = {
    GET_STATES: 'getStates'
};

export const STATES_MUTATIONS = {
    STATES_RESET: 'statesReset',
    STATES_PROGRESS: 'statesInProgress',
    STATES_SUCCESS: 'updateStates',
    STATES_FAILURE: 'statesFailure'
};
