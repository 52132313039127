export const DESIGNATIONS = {
    GET_DESIGNATIONS: 'getDesignations'
};

export const DESIGNATION_MUTATIONS = {
    DESIGNATION_RESET: 'designationReset',
    DESIGNATION_PROGRESS: 'designationInProgress',
    DESIGNATION_SUCCESS: 'updateDesignation',
    DESIGNATION_FAILURE: 'designationFailure'
};
