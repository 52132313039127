export const COURSES = {
    GET_COURSE: 'getCourses'
};

export const COURSES_MUTATIONS = {
    COURSES_RESET: 'COURSESReset',
    COURSES_PROGRESS: 'coursesInProgress',
    COURSES_SUCCESS: 'updateCourses',
    COURSES_FAILURE: 'coursesFailure'
};
