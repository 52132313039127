
import { DESIGNATION_MUTATIONS } from './constants';

export async function getDesignations({ commit },
    { param } = {}) {
    try {
        let designations = [];
        commit(DESIGNATION_MUTATIONS.DESIGNATION_PROGRESS);
        await param.getDesignations().then((data) => {
            data.designations.forEach((element) => {
              designations.push({
                value: element.role_id,
                text: element.role,
              });
            });
          });
        commit(DESIGNATION_MUTATIONS.DESIGNATION_SUCCESS, designations);
    } catch(error) {
        commit(DESIGNATION_MUTATIONS.DESIGNATION_FAILURE, error);
    }
}