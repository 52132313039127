const URIS = {
    'login':'/jobseeker_login',
    'register':'/jobseeker_register',
    'employer_login':'/employer_login',
    'employer_register':'/employer_regitser',
    'employee' : '/me',
    'profile_image':'/update_profile_pic',
    'states':'/states',
    'subcity':'/sublocation/',
    'courses':'/courses',
    'specifications':'/specifications',
    'designations':'/designations',
    'jobrole':'/jobrole',
    'cities':'/city',
    'university':'/university',
    'personaldetails':'/personaldetails',
    'updateExp':'/workexperience',
    'editResume':'/updateresume',
    'update_alerts':'/update_alerts',

    'high_qualification': '/morequalifiation',
    'experience':'/company',
    'careerpreference':'/careerpreference',
    'languages':'/languages',
    'skills':'/skills',
    'logout': '/logout',
    'listing': '/job_listing',
    'recListing':'/recommended_jobs',
    'jobDetails':'/job_listing/',
    'jobapplications':'/jobapplications',
    'editXth':'/update_xth',
    'editXiith':'/update_xii',
    'updatehq':'/updatehq',
    'morequalifiation':'/morequalifiation',
    'applied_list':'/applied_jobs/',
    'jobsByUrl':'/jobs_by_url',
    'seeker_logout':'/logout',
    'remove_company':'/remove_company',
    'remove_qualification':'/remove_qualification',

    'post_job':'/job/post',
    'job_designations':'/jobdesignations',
    'jobcategories':'/jobcategories',
    'employer':'/mydashboard',
    'list_jobs':'/job/list',
    'getJob':'/job',
    'reset_password':'/reset_password',
    'verify_email': '/verify_email',
    'send_verification': '/send_verification',
    'view_contact' : '/view_contact',
    'sendOtp': '/sendOtp',
    'verifyOtp': '/verifyOtp',
    'active_jobfairs': '/active_jobfairs',
    'questionnaire':'/list_questionnaire',
    'submit_questionnaire':'/submit_questionnaire',
    'send_reset_link': '/send_reset_link',
    'forgot_password_reset': '/forgot_password_reset',
    'video_profile':'/video_profile',
    'listUrl': '/listURL',
    'contact':'/addContact',
    'candidate_profile':'/candidate-profile',
    'updates':'/updates',
    'interview':'/interview',
    'assessments':'/assessments',
    'comments':'/comments',
    'institutes':'/institutes',
    'training_institutes':'/training_institutes',
    'course_categories':'/course_categories',
    'training_details':'/institute/training_details',
    'formDetails': '/private/job/form',
    'post':'/post',
    'tag_college':'/college/tag_college',
    'profile_views':'/profile_views',
    'redirects':'/redirects'
}

export default URIS;