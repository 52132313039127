
import { JOB_ROLE_MUTATIONS } from './constants';

export async function getJobrole({ commit },
    { param } = {}) {
    try {
        let jobrole = [];
        commit(JOB_ROLE_MUTATIONS.JOB_ROLE_PROGRESS);
        await param.getJobrole().then((data) => {
            data.jobroles.forEach((element) => {
              jobrole.push({
                value: element.role_id,
                text: element.role,
              });
            });
          });
        commit(JOB_ROLE_MUTATIONS.JOB_ROLE_SUCCESS, jobrole);
    } catch(error) {
        commit(JOB_ROLE_MUTATIONS.JOB_ROLE_FAILURE, error);
    }
}