export const BRANCHES = {
    GET_BRANCH: 'getBranches'
};

export const BRANCHES_MUTATIONS = {
    BRANCHES_RESET: 'BRANCHESReset',
    BRANCHES_PROGRESS: 'branchesInProgress',
    BRANCHES_SUCCESS: 'updateBranches',
    BRANCHES_FAILURE: 'branchesFailure'
};
