import {
  BFormGroup,
  BCard,
  BCardBody,
  BButton,
  BCollapse,
  BRow,
  BCol,
  BCardText,
  BBadge,
  BModal,
  BAlert,
  BNavbar,
  BNavbarNav,
  BNavItem,
  BFormFile,
  BNavbarToggle,
  BCardGroup,
  BTooltip,
  BFormCheckbox,
  ModalPlugin,
  VBToggle,
  BFormRadio,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'

const BootstrapVuePlugin = {
  install(Vue) {
    Vue.component('BCard', BCard)
    Vue.component('BCardBody', BCardBody)
    Vue.component('BButton', BButton)
    Vue.component('BCollapse', BCollapse)
    Vue.component('BRow', BRow)
    Vue.component('BCol', BCol)
    Vue.component('BCardText', BCardText)
    Vue.component('BBadge', BBadge)
    Vue.component('BModal', BModal)
    Vue.component('BAlert', BAlert)
    Vue.component('BNavbar', BNavbar)
    Vue.component('BNavbarNav', BNavbarNav)
    Vue.component('BNavItem', BNavItem)
    Vue.component('BFormFile', BFormFile)
    Vue.component('BCardGroup', BCardGroup)
    Vue.component('BNavbarToggle', BNavbarToggle)
    Vue.component('BTooltip', BTooltip)
    Vue.component('BFormCheckbox', BFormCheckbox)
    Vue.component('BFormGroup', BFormGroup)
    Vue.component('BFormRadio', BFormRadio)
    Vue.component('BFormSelect', BFormSelect)
    Vue.component('BFormSelectOption', BFormSelectOption)
    
    Vue.use(ModalPlugin)
    Vue.directive('b-toggle', VBToggle)
  }
}

export default BootstrapVuePlugin