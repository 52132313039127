<template>
  <v-app>
    <div id="app">
     <Header v-if="$route.meta.header === 1"/>
      <!-- <Marquee v-if="$route.meta.header === 1"/> -->
      <SubHeader v-if="$route.meta.header === 1&&this.$cookies.get('hire-seeker')&&(!$route.fullPath.split('/').includes('questionnaire'))&&(!this.$cookies.get('hire-seeker').resume_path||!this.$cookies.get('hire-seeker').visume_path)"/>
      <router-view/>
      <Footer v-if="$route.meta.header === 1"/>
      <VisumeModal v-if="$route.meta.header === 1"/>
    </div>
  </v-app>
</template>

<script>
const VisumeModal = () => import(/* webpackPrefetch: true */ "@/components/VisumeModal");
const Header = () => import(/* webpackPrefetch: true */ "@/components/Header");
// const Marquee = () => import(/* webpackPrefetch: true */ "@/components/Marquee");
const SubHeader = () => import(/* webpackPrefetch: true */ "@/components/SubHeader");
const Footer = () => import(/* webpackPrefetch: true */ "@/components/Footer");

export default {
  name: 'App',
  components: {
    Header,
    // Marquee,
    SubHeader,
    Footer,
    VisumeModal
  }
}
</script>