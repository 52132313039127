import Vue from 'vue';
import App from './App.vue';
import VueMeta from 'vue-meta';
import BootstrapVuePlugin from './plugins/bootstrap-vue.js';
import { icons } from './plugins/mdi.js'; 
import VueRouter from 'vue-router';
import router from './router';
import store from './store/index';
import VueCookies from 'vue-cookies';
import 'vuetify/dist/vuetify.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(BootstrapVuePlugin);
Vue.use(VueCookies);
Vue.use(VueRouter);

Vue.prototype.$S3_url = process.env.VUE_APP_S3_URL;
Vue.prototype.$S3_bucket_url = process.env.VUE_APP_S3_BUCKET_URL;
Vue.prototype.$S3_hireclap_bucket = process.env.VUE_APP_S3_HIRECLAP_BUCKET;
Vue.prototype.$icons = icons;

export const eventBus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  delimiters: ['${', '}']
}).$mount('#app');

