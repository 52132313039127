export const JOB_ROLE = {
    GET_JOB_ROLE: 'getJobrole'
};

export const JOB_ROLE_MUTATIONS = {
    JOB_ROLE_RESET: 'jobRoleReset',
    JOB_ROLE_PROGRESS: 'jobRoleInProgress',
    JOB_ROLE_SUCCESS: 'updateJobRole',
    JOB_ROLE_FAILURE: 'jobRoleFailure'
};
