import prepareErrorForVuexStore from '../../utils/prepare-error-for-vuex-store';
import initialState from './initial-state';

export function resetState(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialState());
}

export function branchesInProgress(state) {
    state.branches.__loading__ = true;
    state.branches.__error__ = null;
}

export function branchesFailure(state, error) {
    state.branches.__error__ = prepareErrorForVuexStore(error, 'data for branches not provided');
    state.branches.__loading__ = false;
}

export function updateBranches(state, branches) {
    state.branches = {
        branches,
        __error__: null,
        __loading__: false
    };
}