import prepareErrorForVuexStore from '../../utils/prepare-error-for-vuex-store';
import initialState from './initial-state';

export function resetState(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialState());
}

export function designationInProgress(state) {
    state.designations.__loading__ = true;
    state.designations.__error__ = null;
}

export function designationFailure(state, error) {
    state.designations.__error__ = prepareErrorForVuexStore(error, 'data for designation not provided');
    state.designations.__loading__ = false;
}

export function updateDesignation(state, designations) {
    state.designations = {
      designations,
        __error__: null,
        __loading__: false
    };
}